import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatBoxService {

  private _closeChat: BehaviorSubject<any> = new BehaviorSubject(null);

  get closeChat$(): Observable<any> {
    return this._closeChat.asObservable();
  }

  constructor() { }

  closeChat() {
    this._closeChat.next(true)
  }

}
