@if (view() === 'WEB') {
  <div class="bbwidge-text-message" [class.bbwidge-text-message-question]="message.type_user === 'E'">
    {{message.text}}
  </div>
} @else {
  <div class="max-w-full bbwidget-message-text"
       [ngClass]="{ 'bbwidget-bubble-chat-question': message().type_user === 'R' }"
       [style.--botMessageBackgroundColor]="backgroundColor() | safe: 'style'"
       [style.--botMessageTextColor]="textColor() | safe: 'style'">
    <markdown
      clipboard
      [clipboardButtonComponent]="clipboardButton"
      class="variable-binding"
      [data]="message().text">
    </markdown>
  </div>
}
