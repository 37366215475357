import {
	Component,
	ViewChild,
	OnInit,
	Output,
	EventEmitter,
	Input,
	ElementRef,
	ChangeDetectorRef,
	HostListener,
	ViewChildren,
	QueryList,} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { MessageQR } from "../../../core/interfaces/IQuickReply";
import { PayLoad, IButtonPL } from "../../../core/interfaces/IPayLoad";
import { SignalRService } from "../../../core/service/signalRService";
import { SharedPropertiesGeneral } from "../../../core/service/sharedPropertiesGeneral";
import { HttpClient } from "@angular/common/http";
import {
	trigger,
	transition,
	query,
	style,
	stagger,
	animate,
	state,
} from "@angular/animations";
import { Menu } from "../../../core/global-variables/menu";
import { Global } from "../../../core/global-variables/global";
import { environment } from "../../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { Bubble, PsMessage } from "../../../core/interfaces/IMessage";
import { v4 as uuidv4 } from "uuid";
// import { GoogleAnalyticsService } from "ngx-google-analytics";
import { LanguageService } from "../../../core/service/language/language.service";
import { AppConfig } from "./../../../../../src/app.config";
import { DeviceDetectorService } from "ngx-device-detector";
// import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { FormCustomComponent } from "../form-custom/form-custom.component";
import { SwiperOptions } from "swiper";
import { PreferenceService } from "../../../core/service/preference.service";
import { MatDialog } from "@angular/material/dialog";
import { ClipboardButtonComponent } from "../../../shared/components/clipboardButtonComponent/clipboardButtonComponent.component";

@Component({
  selector: 'chat-conversation-chat',
  templateUrl: './conversation-chat.component.html',
  styleUrl: './conversation-chat.component.css',
  animations: [
    trigger("inputAnimationWeb", [
      state("false", style({ bottom: "40%" })),
      state("true", style({ bottom: "0" })),
      transition("* => *", [animate("1s linear")]),
    ]),
    trigger('fadeOut', [
      state("true", style({ opacity: 1 })),
      state("false`", style({ opacity: 0 })),
      transition(':leave', [animate('1s linear')
      ])
    ])
  ]
})
export class ConversationChatComponent implements OnInit{

  @ViewChild("scroll") scroll: ElementRef;
    @ViewChildren("messageContent") messageContent: QueryList<any>;
    @ViewChildren("messageResponse") messageResponse: QueryList<any>;
    @Output("minimize") minimize: EventEmitter<any> = new EventEmitter<any>();
    @Output("isShowWelcomeChat") isShowWelcomeChat: EventEmitter<any> =
      new EventEmitter<any>();
    @Output("toggleChat") toggleChat: EventEmitter<any> = new EventEmitter<any>();
    @Output("clearRezise") clearRezise: EventEmitter<any> =
      new EventEmitter<any>();
    @Input() global: Global;
    @Input() helpResize: string;
    @Input() isMaximized: boolean;
    resizeWidth = false;
    isOpenChat: any = false;
    chatView: string;
    env: string = environment.production ? "production" : "development";
    brandbotLogo = "../../assets/images/logo-brandbot.png";
    list_message: Bubble[];
    username = "";
    message = new PsMessage();
    inputMessage = "";
    show_typing = false;
    is_mobile: boolean;
    showBrandbotLogo = true;
    send_to = "BOT";
    is_human_agent = false;
    item_carousel = 1;
    loading_mobile = false;
    width = "auto";
    height = "100%";
    showModal = false;
    rateService = false;
    supportIntro = true;
    burgerMenu = false;
    messageFontSize = "16px";
    item: any;
    showUpload: boolean;
    showDownload: boolean;
    showForm: boolean;
    messageObjectInitial = "";
    formContent: any;
    canSendMessage: Boolean;
    isTimeOut: Boolean;
    isConnected: Boolean = false;
    showFeedback: Boolean = false;
    firstMessageSent = false;
    isSoundNotication = true;
    idleState = "";
    latitude: number;
    longitude: number;
    files = [];
    hidden = true;
    removable = true;
    showEmoji = false;
    scrollFirstResponse = false;
    hiAmBotMessage = null;
    device: any;
    inputAnimation = false;
  
    placeholders: string[] = [];
    currentPlaceholderIndex: number = 0;
    interval: any;
    configSwiper: SwiperOptions;
  
    menuBottom = [];
    config: AppConfig;
  
    readonly clipboardButton = ClipboardButtonComponent;
  
    dimensionActual: string;
    audio: any;
    firstTime = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
  
    constructor(
      public _signalRService: SignalRService,
      private _preferenceService: PreferenceService,
      private _dialog: MatDialog,
      private http: HttpClient,
      public menu: Menu,
      private translateService: TranslateService,
      private _changeDetectorRef: ChangeDetectorRef,
      public _sharedPropertiesGeneral: SharedPropertiesGeneral,
      private _languageService: LanguageService,
      // private $gaService: GoogleAnalyticsService,
      private _deviceDetectorService: DeviceDetectorService,
    ) {}
  
    @HostListener("window:beforeunload")
    beforeUnload() {
      if (this.config?.useHistory) {
        const chatHistory = {
          language: this.translateService.currentLang,
          messages: this.list_message,
        };
        sessionStorage.setItem(
          `bbwidget_chatHistory_${this.config.projectId}`,
          JSON.stringify(chatHistory),
        );
      }
    }
  
    @HostListener("window:resize", ["$event"])
    onResize(event) {
      this.checkDimension();
    }
  
    ngOnInit() {
      let configSwiper = {
        loop: true,
        spaceBetween: 30,
        slidesPerView: 1,
        pagination: {
          clickable: true,
        },
      };
  
      this.configSwiper = configSwiper;
      this.global.socialId = uuidv4();
      this.startPlaceholderRotation();
  
      this._preferenceService.config$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((config: any) => {
          if (config) {
            this.config = config;
            console.log("config", config);
  
            if (this.firstTime) {
              this.chatView = config.chatView;
              if (config.notificationAudio) {
                this.audio = document.getElementById("saludo");
              }
              this.checkDimension();
              this.device = this._deviceDetectorService.getDeviceInfo();
              this.initChat();
  
              if (window.outerHeight > window.outerWidth) {
                this.is_mobile = true;
              }
  
              if (this.chatView != "BUBBLE" && !this.is_mobile) {
                this.item_carousel = 3;
              } else {
                this.messageFontSize = "14px";
              }
  
              if (this.config.bannerSlides?.length > 1) {
                configSwiper["autoplay"] = {
                  delay: 5000,
                  disableOnInteraction: false,
                };
              }
  
              this.placeholders = this.config.inputPlaceholders;
              this.showBrandbotLogo = this.config.showBrandbotLogo;
              this.global.socketUrl = this.config.webhooks[this.env].signalR;
              this.firstTime = false;
            }
          }
  
          this._changeDetectorRef.markForCheck();
        });
  
      this._languageService.lang$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((lang: any) => {
          if (this.isConnected) {
            this.resetChat();
          }
  
          // Mark for check
          this._changeDetectorRef.markForCheck();
        });
  
      this._signalRService.initConnection();
      this.startChat(0);
    }
  
    ngAfterViewInit(): void {
      this.messageResponse.changes.subscribe((_) => {
        this.scrollToBottom();
        setTimeout(() => {
          this.list_message[this.list_message.length - 1].response.map(
            (element: any, index: number) => {
              if (element.type_message === "video") {
                const videoResponse = document.getElementById(
                  `videoResponse_${this.list_message.length - 1}_${index}`,
                ) as HTMLVideoElement;
  
                videoResponse?.addEventListener("playing", () => {
                  element.text.message.attachment.payload.media.map(
                    (video: any) => {
                      video.autoplay = false;
                      return video;
                    },
                  );
                });
              }
              return element;
            },
          );
        }, 10);
      });
    }
  
    ngOnDestroy() {
      this._signalRService.removeConnection();
    }
  
    initChat(): void {
      this.list_message = [];
      const chatHistory = JSON.parse(
        sessionStorage.getItem(`bbwidget_chatHistory_${this.config.projectId}`),
      );
      if (
        !!chatHistory &&
        chatHistory.language == this.translateService.currentLang
      ) {
        this.isShowWelcomeChat.emit(false);
        this.list_message = chatHistory.messages;
        console.log(this.list_message)
        this.menuBottom = JSON.parse(
          sessionStorage.getItem(`bbwidget_menuBottom_${this.config.projectId}`),
        );
        setTimeout(() => {
          this.scrollToBottom();
        }, 100);
      }
      this.firstMessageSent = this.list_message.length > 0;
    }
  
    private initSignalR(): void {
      this.loading_mobile = true;
      this._signalRService.startConnection();
  
      // if connection exists it can call of method.
      this._signalRService.connectionEstablished.subscribe((result: boolean) => {
        this.isConnected = result;
        this.canSendMessage = result;
        this.idleState = this.canSendMessage ? "Conectado" : "Desconectado";
        if (this.canSendMessage && !this.firstMessageSent) {
          this.sendFirstMessage();
        } else {
          this.menuBottom = JSON.parse(
            sessionStorage.getItem(
              `bbwidget_menuBottom_${this.config.projectId}`,
            ),
          );
        }
      });
      this._signalRService.connectionFailed.subscribe((result: boolean) => {
        if (this.global.connectionAttempts >= 1) {
          this.showFeedback = true;
        }
      });
  
      // finally our service method to call when response received from server event
      // and transfer response to some variable to be shwon on the browser.
      this._signalRService.messageReceived.subscribe((message: PsMessage) => {
        console.log(message);
        // if (this.message?.type === "typing_on") {
        // 	this.show_typing = true;
        // 	setTimeout(() => {
        // 		this.scrollToBottom();
        // 	}, 200);
        // } else
        if (message.intentId != "CANCEL") {
          if (this.chatView != "WEB" || message.intentId != "HI_IM_BOT") {
            this.message = message;
            const content =
              this.message.content.indexOf('"text"') > 0
                ? JSON.parse(this.message.content).message
                : this.message.content;
  
            this.setMessage("R", content);
            if (message.intentId !== "WAIT_FOR_ANSWER") {
              this.show_typing = false;
            } else {
              setTimeout(() => {
                this.show_typing = true;
              }, 300);
            }
  
            sessionStorage.removeItem(
              `bbwidget_menuBottom_${this.config.projectId}`,
            );
            sessionStorage.removeItem(
              `bbwidget_hiAmBotMessage_${this.config.projectId}`,
            );
          } else {
            if (message.type == "quick_reply") {
              this.menuBottom = JSON.parse(message.content).message.quick_replies;
              console.log({ menuBottom: this.menuBottom });
              sessionStorage.setItem(
                `bbwidget_menuBottom_${this.config.projectId}`,
                JSON.stringify(this.menuBottom),
              );
            } else if (message.type == "text") {
              this.hiAmBotMessage = JSON.parse(message.content).message.text;
              console.log({ hiAmBotMessage: this.hiAmBotMessage });
              sessionStorage.setItem(
                `bbwidget_hiAmBotMessage_${this.config.projectId}`,
                this.hiAmBotMessage,
              );
            }
          }
        }
        this.audio?.play();
      });
  
      this._signalRService.onRestart.subscribe(() => {
        this.global.restart = false;
        this.sendFirstMessage();
      });
    }
  
    toggleChatSend(event: any) {
      this.toggleChat.emit(event);
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    }
  
    startPlaceholderRotation() {
      this.interval = setInterval(() => {
        this.currentPlaceholderIndex =
          (this.currentPlaceholderIndex + 1) % this.placeholders.length;
        this._changeDetectorRef.markForCheck();
      }, 5000); // 2 segundos entre cambios
    }
  
    // Función para obtener el placeholder actual
    getPlaceholder(): string {
      return this.placeholders[this.currentPlaceholderIndex];
    }
  
    sendFirstMessage(): void {
      const firstMessage = this.config.firstMessage || "info|HI_IM_BOT";
      const message = this.buildMessage("postback", firstMessage);
      this._signalRService.sendMessage(message);
      this.firstMessageSent = true;
  
      if (this.config.menuBottomView !== "NONE") {
        this.sendMenuBottomMessage();
      }
    }
  
    sendMenuBottomMessage(): void {
      // const menuBottommessage = "info|MENU_BOTTOM";
      // const message = this.buildMessage("postback", menuBottommessage);
      // this._signalRService.sendMessage(message);
    }
  
    goBack() {
      if (!!this.rateService) {
      } else {
        this.list_message = [];
        this.supportIntro = true;
      }
    }
  
    startChat(event: any) {
      if (!this.isConnected) {
        this.initSignalR();
      } else {
        if (this.list_message.length == 0) {
          this.sendFirstMessage();
        }
      }
      this.supportIntro = false;
    }
  
    toogleNotification() {
      this.isSoundNotication = !this.isSoundNotication;
    }
  
    setMessage(type_user: string, content: any) {
      const lastMessage = this.list_message[this.list_message.length - 1];
      let newContent = content.text ? content.text : content;
      const response = {
        date: this.GetDateFormat(),
        type_user,
        backgroundColor:
          this.GetTypeMessage(this.message?.type) === "html"
            ? content.backgroundColor
            : null,
        text: newContent ? this.textOrJson(newContent) : "Form",
        type_message:
          type_user === "E" ? "TX" : this.GetTypeMessage(this.message?.type),
        quick_reply:
          type_user !== "E" && this.GetTypeMessage(this.message?.type) === "QR"
            ? JSON.parse(this.message.content)
            : null,
        payload:
          (type_user !== "E" &&
            this.GetTypeMessage(this.message?.type) === "PL") ||
          this.GetTypeMessage(this.message?.type) === "TB"
            ? JSON.parse(this.message.content)
            : null,
        content:
          type_user !== "E" && this.GetTypeMessage(this.message?.type) === "CO"
            ? JSON.parse(this.message.content)
            : null,
        visible:
          (type_user !== "E" &&
            this.GetTypeMessage(this.message?.type) === "PL") ||
          this.GetTypeMessage(this.message?.type) === "TB"
            ? false
            : true,
        isSound: this.isSoundNotication,
      };
  
      if (lastMessage?.type_user != type_user) {
        const mensaje = {
          username: this.username,
          type_user,
          response: [response],
        };
  
        if (this.GetTypeMessage(this.message?.type) === "FORM") {
          this.formContent = response.text;
          this.openForm();
        }
  
        this.list_message.push(mensaje);
        this.messageObjectInitial = JSON.stringify(mensaje);
      } else {
        this.list_message[this.list_message?.length - 1].response.push(response);
      }
  
      this._changeDetectorRef.markForCheck();
    }
  
    pushMessage(messageType: string) {
      let mensaje: Bubble;
      if (messageType === "E") {
        mensaje = {
          username: this.username,
          type_user: messageType,
          response: [
            {
              date: this.GetDateFormat(),
              type_user: messageType,
              text: this.inputMessage,
              type_message: "TX",
              quick_reply: null,
              payload: null,
              content: null,
              visible: true,
              isSound: false,
            },
          ],
        };
        this.list_message.push(mensaje);
      } else {
        if (!!this.message.content) {
          if (messageType === "A") {
            mensaje = {
              username: "App",
              type_user: messageType,
              response: [
                {
                  date: this.GetDateFormat(),
                  type_user: messageType,
                  text:
                    this.message.content.indexOf('"text"') > 0
                      ? JSON.parse(this.message.content).message.text
                      : this.message.content,
                  type_message: this.GetTypeMessage(this.message?.type),
                  quick_reply:
                    this.GetTypeMessage(this.message?.type) === "QR"
                      ? <MessageQR>JSON.parse(this.message.content)
                      : null,
                  payload:
                    this.GetTypeMessage(this.message?.type) === "PL" ||
                    this.GetTypeMessage(this.message?.type) === "TB"
                      ? <PayLoad>JSON.parse(this.message.content)
                      : null,
                  content:
                    this.GetTypeMessage(this.message?.type) === "CO"
                      ? JSON.parse(this.message.content)
                      : null,
                  visible:
                    this.GetTypeMessage(this.message?.type) === "PL" ||
                    this.GetTypeMessage(this.message?.type) === "TB"
                      ? false
                      : true,
                  isSound: false,
                },
              ],
            };
  
            this.list_message.push(mensaje);
          } else {
            // Se verifica si el objeto que viene es de tipo location
            this.VerifyLocationObject();
            mensaje = {
              username: messageType === "E" ? "UserMimg" : this.username,
              type_user: messageType,
              response: [
                {
                  date: this.GetDateFormat(),
                  type_user: messageType,
                  text:
                    this.message.content.indexOf('"text"') > 0
                      ? JSON.parse(this.message.content).message.text
                      : this.message.content,
                  type_message: this.GetTypeMessage(this.message?.type),
                  quick_reply:
                    this.GetTypeMessage(this.message?.type) === "QR"
                      ? <MessageQR>JSON.parse(this.message.content)
                      : null,
                  payload:
                    this.GetTypeMessage(this.message?.type) === "PL" ||
                    this.GetTypeMessage(this.message?.type) === "TB"
                      ? <PayLoad>JSON.parse(this.message.content)
                      : null,
                  content:
                    this.GetTypeMessage(this.message?.type) === "CO"
                      ? JSON.parse(this.message.content)
                      : null,
                  visible:
                    this.GetTypeMessage(this.message?.type) === "PL" ||
                    this.GetTypeMessage(this.message?.type) === "TB"
                      ? false
                      : true,
                  isSound: false,
                },
              ],
            };
  
            if (
              this.message?.type !== "typing_on" &&
              this.message?.type !== "typing_off"
            ) {
              this.list_message.push(mensaje);
            }
          }
        }
      }
  
      this.show_typing = false;
      this._changeDetectorRef.detectChanges();
    }
  
    sendMessage(event: string) {
      this.inputMessage = event
      if (this.inputMessage === "") {
        return;
      }
      this.hiAmBotMessage = null;
      this.menuBottom = null;
      this.setMessage("E", { text: this.inputMessage });
      const message = this.buildMessage("text", this.inputMessage);
      this._signalRService.sendMessage(message);
      this.message = null;
      this.show_typing = true;
      this.inputMessage = "";
    }
  
    quickReplies(qr: any) {
      if (qr.content_type !== "form_submit") {
        this.setMessage("E", { text: qr.title });
        setTimeout(() => {
          const message = this.buildMessage("postback", qr.payload);
          this._signalRService.sendMessage(message);
        });
      } else {
        this.sendForm(qr.payload);
      }
    }
  
    private sendForm(payload: any) {
      const { method, action, data } = JSON.parse(payload);
      const form = document.createElement("form");
      form.method = method;
      form.action = decodeURIComponent(action);
  
      data.forEach((element: any) => {
        const key = Object.keys(element)[0];
        const value = Object.values(element)[0];
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = value as string;
        form.appendChild(input);
      });
      document.body.appendChild(form);
      form.submit();
    }
  
    GenericButton(button: IButtonPL) {
      // this.$gaService.event("chat_intentions", "intentions", button.title);
      if (button.type === "postback") {
        this.setMessage("E", { text: button.title });
        const message = this.buildMessage("postback", button.payload);
        this._signalRService.sendMessage(message);
      }
  
      if (button.type === "web_url") {
        if (button.urlTarget == "_blank") {
          window.open(button.url, "_blank");
        } else {
          window.location.href = button.url;
        }
      }
    }
  
    GetTypeMessage(type: string) {
      if (type === "quick_reply" || type === "quick_replies") {
        return "QR";
      }
  
      if (type === "generic_template" || type === "generic") {
        return "PL";
      }
  
      if (type === "text" || type === undefined || type === "simple_text") {
        return "TX";
      }
  
      if (type === "form") {
        return "FORM";
      }
  
      if (type === "content") {
        return "CO";
      }
  
      if (type === "button_template" || type === "button") {
        return "TB";
      }
  
      return type;
    }
  
    GetDateFormat(): string {
      const date = new Date();
      const dateFormat =
        date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2);
      return dateFormat; // "Mar 22, 2017, 4:00:28 AM";
    }
  
    MenuSelected(type, action, option: any = null) {
      switch (type) {
        case "T":
          const message = this.buildMessage("postback", action);
          this._signalRService.sendMessage(message);
          break;
  
        case "O":
          if (action === "BorrarChat") {
            return this.resetChat();
          }
  
          if (action === "CerrarChat") {
            return this.OpenDialog();
          }
  
          if (action === "ExpandirChat") {
            option.map((children) => {
              if (children.action === "ExpandirChat") {
                children.active = false;
              }
              if (children.action === "ContraerChat") {
                children.active = true;
              }
            });
            return; //this.Resize();
          }
  
          if (action === "ContraerChat") {
            option.map((children) => {
              if (children.action === "ExpandirChat") {
                children.active = true;
              }
              if (children.action === "ContraerChat") {
                children.active = false;
              }
            });
            return; //this.Resize();
          }
  
          if (action === "Regresar") {
            return this.goBack();
          }
  
          break;
  
        case "Link":
          window.open(action, "_blank");
      }
  
      return true;
    }
  
    uploadField($event: any) {
      this.showUpload = false;
      const fieldMessage = `<div style="display: flex;height: 100%;justify-content: space-between;align-items: center;">${$event.map(
        (file: any) => {
          return `<div class="file-icon" style="width: 50px"><img style="width: 100%;" src="assets/images/file.png" alt=""></div><div><h4 style="font-size: 14px;font-weight: 500;margin:0">${file?.name}</h4><p style="font-size: 12px;font-weight: 500;margin: 0;margin-bottom: 0.25rem;">${this.formatBytes(
            file?.size,
          )}</p></div>`;
        },
      )}</div>`;
      this.setMessage("E", { text: fieldMessage });
    }
  
    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes, decimals = 0) {
      if (bytes === 0) {
        return "0 Bytes";
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals || 2;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
  
    GetCurrentLocation() {
      const text =
        '{"latitud":"' +
        this.latitude +
        '", "longitud":"' +
        this.longitude +
        '"}';
      const message = this.buildMessage("message-location", text);
      this._signalRService.sendMessage(message);
    }
  
    BorrarChat() {
      this.list_message = [];
      return true;
    }
  
    mapClicked(event: any) {
      this.latitude = event.coords.lat;
      this.longitude = event.coords.lng;
    }
  
    VerifyLocationObject() {
      if (this.message?.type === "quick_reply") {
        const quick_reply = <MessageQR>JSON.parse(this.message.content);
        if (quick_reply.message.quick_replies[0]?.content_type === "location") {
          // Se obtienen las coordenadas la ubicación actual
          if (!!navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
              },
              (error) => {
                this.http
                  .get("https://freegeoip.app/json/")
                  .subscribe((location: any) => {
                    const result = JSON.parse(location._body);
                    this.latitude = result.latitude;
                    this.longitude = result.longitude;
                  });
              },
            );
          } else {
            this.http
              .get("https://freegeoip.app/json/")
              .subscribe((location: any) => {
                const result = JSON.parse(location._body);
                this.latitude = result.latitude;
                this.longitude = result.longitude;
              });
          }
        }
      }
    }
  
    clearReziseSend(event: boolean) {
      this.clearRezise.emit(event);
    }
  
    minimizeSend() {
      this.minimize.emit();
    }
  
    SendEmoji(e) {
      const mensaje = {
        username: this.username,
        type_user: "E",
        response: [
          {
            date: this.GetDateFormat(),
            type_user: "E",
            text: e.emoji.id,
            type_message: "EM",
            quick_reply: null,
            payload: null,
            content: null,
            visible: true,
            isSound: false,
          },
        ],
      };
      this.list_message.push(mensaje);
      const message = this.buildMessage("text", e.emoji.native);
      this._signalRService.sendMessage(message);
    }
  
    inputAnimationFuction() {
      setTimeout(() => {
        if (this.inputMessage.length > 0 || this.burgerMenu) {
          this.inputAnimation = true;
        } else {
          this.inputAnimation = false;
        }
      }, 500);
    }
  
    OpenDialog(): void {
      if (!this.rateService) {
        this.showModal = true;
      } else {
        this.minimize.emit(0);
        this.BorrarChat();
        setTimeout(() => {
          const message = this.buildMessage("text", "inicio");
          this._signalRService.sendMessage(message);
        }, 1500);
      }
    }
  
    OnNoClick(): void {
      this.showModal = false;
    }
  
    resetChat(): void {
      this.global.restart = true;
      const message = this.buildMessage("text", "cancelar");
      // this.$gaService.event(
      // 	"chat_resstart",
      // 	"user_interactions",
      // 	"chat_resstart",
      // );
      this._signalRService.sendMessage(message);
      this.showUpload = false;
      setTimeout(() => {
        this.BorrarChat();
        this.sendFirstMessage();
        // 	// this.rateService = false;
      }, 100);
    }
  
    EndChat(): void {
      if (this.global.interaction) {
        this.MenuSelected("T", "trans|11110");
        this.showModal = false;
      } else {
        this.minimize.emit(0);
        this.showModal = false;
      }
    }
  
    private scrollToBottom() {
      if (this.scroll) {
        try {
          // let scroll: number;
          // scroll = this.scroll.nativeElement.scrollHeight;
          // if (
          // 	this.messageContent.last.nativeElement.clientHeight >
          // 	this.scroll.nativeElement.clientHeight
          // ) {
          const value = this.chatView == "BANNER" ? 300 : 10;
          const scroll =
            this.scroll.nativeElement.scrollHeight -
            (this.messageContent.last.nativeElement.clientHeight + value);
          // }
  
          this.scroll.nativeElement.scroll({
            top: scroll,
            left: 0,
            behavior: "smooth",
          });
          this._changeDetectorRef.markForCheck();
        } catch (err) {
          console.error("Scroll error:", err);
        }
      }
    }
  
    getMessageUsername(type_user) {
      if (type_user === "R") {
        return this.config.chatbotName;
      } else {
        return "_MESSAGE.GUEST_USERNAME";
      }
    }
  
    buildMessage(type: string, text: string) {
      const lang = sessionStorage.getItem(
        `bb_widget_language_${this.config.projectId}`,
      )
        ? sessionStorage.getItem(`bb_widget_language_${this.config.projectId}`)
        : this.translateService.currentLang;
      return {
        type: type,
        content: text,
        channelId: this.global.channelId,
        channelAccount: "",
        socialId: this.global.socialId,
        socialName: "",
        languageId: lang.toUpperCase(),
        projectId: this.config.projectId,
        instanceId: "",
        userId: "",
        userName: "",
        userEmail: "",
        userTimezone: "",
      };
    }
  
    sendform(event: any) {
      this.setMessage("E", { text: "Form submitted 📄" });
      const message = this.buildMessage("postback", event);
      this._signalRService.sendMessage(message);
      this.showForm = false;
    }
  
    textOrJson(value: string) {
      try {
        return JSON.parse(value);
      } catch (error) {
        return value;
      }
    }
  
    openForm() {
      const dialogRef = this._dialog.open(FormCustomComponent, {
        data: {
          formContent: this.formContent,
        },
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          // this.cancelForm(type, text);
        }
      });
    }
  
    checkDimension() {
      if (this.chatView != "BUBBLE") {
        const widthTemp = window.innerWidth;
        if (widthTemp < 640) {
          this.dimensionActual = "sm";
        } else if (widthTemp >= 640 && widthTemp < 768) {
          this.dimensionActual = "md";
        } else if (widthTemp >= 768 && widthTemp < 1204) {
          this.dimensionActual = "lg";
        } else if (widthTemp >= 1204 && widthTemp < 1280) {
          this.dimensionActual = "xl";
        } else {
          this.dimensionActual = "2xl";
        }
      }
    }


  // list_message: InputSignal<any[]> = input<any[]>();
  // config: AppConfig;
  // private _unsubscribeAll: Subject  <any> = new Subject<any>();

  // private _translateService: TranslateService = inject(TranslateService);
  // private _preferenceService: PreferenceService = inject(PreferenceService);

  // @HostListener("window:beforeunload")
  //   beforeUnload() {
  //     if (this.config?.useHistory) {
  //       const chatHistory = {
  //         language: this._translateService.currentLang,
  //         messages: this.list_message,
  //       };
  //       sessionStorage.setItem(
  //         `bbwidget_chatHistory_${this.config.projectId}`,
  //         JSON.stringify(chatHistory),
  //       );
  //     }
  //   }

  //   ngOnInit(): void {
  //     this._preferenceService.config$
  //         .pipe(takeUntil(this._unsubscribeAll))
  //         .subscribe((config: any) => {
      
  //           if (config) {
  //             this.config = config
        
  //             if (this.firstTime) {
  //               this.chatView = config.chatView;
  //               if (config.notificationAudio) {
  //                 this.audio = document.getElementById("saludo");
  //               }
  //               this.checkDimension();
  //               this.device = this._deviceDetectorService.getDeviceInfo();
  //               this.initChat();
        
  //               if (window.outerHeight > window.outerWidth) {
  //                 this.is_mobile = true;
  //               }
        
  //               if (this.chatView != "BUBBLE" && !this.is_mobile) {
  //                 this.item_carousel = 3;
  //               } else {
  //                 this.messageFontSize = "14px";
  //               }
        
  //               if (this.config.bannerSlides?.length > 1) {
  //                 configSwiper["autoplay"] = {
  //                   delay: 5000,
  //                   disableOnInteraction: false,
  //                 };
  //               }
        
  //               this.placeholders = this.config.inputPlaceholders;
  //               this.showBrandbotLogo = this.config.showBrandbotLogo;
  //               this.global.socketUrl = this.config.webhooks[this.env].signalR;
  //               this.firstTime = false
  //             }
  //           }
      
      
  //           this._changeDetectorRef.markForCheck();
      
  //         });
  //   }
    

}
