import { NgModule } from "@angular/core";
import { Global } from "./global-variables/global";
import { Menu } from "./global-variables/menu";
import { PlaceHolders } from "./global-variables/placeholders";
import { SignalRService } from "./service/signalRService";
import { FileDragDropDirective } from "./directive/file-drag-drop.directive";
import { SharedPropertiesGeneral } from "./service/sharedPropertiesGeneral";
import { LanguageService } from "./service/language/language.service";
import { ChatBoxService } from "./service/chatBox.service";
@NgModule({
	providers: [
		SignalRService,
		// ChatBoxService,
		PlaceHolders,
		Menu,
		Global,
		SharedPropertiesGeneral,
	],
	declarations: [FileDragDropDirective],
})
export class CoreModule {}
