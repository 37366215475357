<div id="brandbot-widget" 
[ngClass]="{'hidden': isMinimizeChat}"
>
	@if (showButton) {
		<div
			class="brandbot-launcher-frame"
			[ngClass]="{
				'bbwidget-brandbot-widget-left': config.position == 'left',
				'bbwidget-brandbot-widget-right': config.position == 'right'
			}"
		>
			<!-- mesaje que se repite -->
			<div class="welcome-chat__left" *ngIf="showMessageRepit">
				<div class="bbwidget-bubble animated zoomIn">
					<div
						class="flex justify-center background-header-icon"
						[style.--avatarBackground]="config?.avatarBackground"
						[style.--shadowColor]="config?.shadowColor"
					>
						<img class="max-w-none" [src]="config?.avatar" />
					</div>
					<div
						class="bbwidget-bubble-chat-question"
						[style.--botMessageBackgroundColor]="
							config.botMessageBackgroundColor | safe: 'style'
						"
						[style.--botMessageTextColor]="
							config.botMessageTextColor | safe: 'style'
						"
					>
						{{ "REMINDER_MESSAGE" | translate }}
					</div>
					<div class="bbwidget-bubble-close" (click)="closeMessageRepit()">
						<mat-icon
							class="flex justify-center items-center w-[18px] h-[18px] text-[10px]"
							>close</mat-icon
						>
					</div>
				</div>
			</div>
			<!-- -------------- -->
			<app-welcome-chat
				*ngIf="config?.startMessage"
				[@openCloseBtn]="isShowWelcomeChat"
				(closeBuble)="closeBubleChat($event)"
				(selectOption)="
					openChat(isShowWelcomeChat ? 'chat_start' : 'chat_reopen_start')
				"
				(openOnlyHome)="openOnlyHome($event)"
				[list_message]="list_message"
				(toogleWelcomeChat)="closeWelcomeChat($event)"
			>
			</app-welcome-chat>
			<div class="brandbot-launcher" *ngIf="isOpenChat">
				<button
					mat-fab
					class="brandbot-launcher-open-icon"
					(click)="closeBubleChat()"
					[ngStyle]="{
						'background-color': 'url(' + config.toolbarBackgroundColor + ')'
					}"
				>
					<mat-icon class="close-icon" style="color: black"
						>keyboard_arrow_down</mat-icon
					>
				</button>
			</div>
			<div class="brandbot-launcher" *ngIf="!isOpenChat">
				<button
					mat-fab
					class="brandbot-launcher-open-icon"
					(click)="
						openChat(isShowWelcomeChat ? 'chat_start' : 'chat_reopen_start')
					"
					[ngStyle]="{ 'background-color': config.launcherButtonBackground }"
				>
					<img [src]="config.launcherButtonImage" alt="" />{{ "" }}
				</button>
			</div>
		</div>
	}
</div>
