import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Global } from "../../../core/global-variables/global";
import { PsMessage } from "../../../core/interfaces/IMessage";
import { SignalRService } from "../../../core/service/signalRService";

@Component({
	selector: "chat-service-rating",
	templateUrl: "./service-rating.component.html",
	styleUrls: ["./service-rating.component.css"],
})
export class ServiceRatingComponent implements OnInit, OnChanges {
	@Input() config: any;
	@Input() data!: any;

	thankYouMsg: boolean = false;
	score: number;
	comment: string;

	constructor(
		private global: Global,
		public translate: TranslateService,
		public signalRService: SignalRService,
	) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		if (!!changes.config) {
			if (!!changes.config.currentValue) {
				this.config = changes.config.currentValue;
			}
		}
	}

	sendMessage(message: any) {
		this.signalRService.sendMessage(JSON.stringify(message));
	}

	SendServiceRating(): void {
		let psMessage: PsMessage = {
			type: "rate-service",
			content: this.score + (!!this.comment ? "&" + this.comment : ""),
			channelId: this.global.channelId,
			channelAccount: "",
			socialId: this.global.socialId,
			socialName: "",
			languageId: this.translate.currentLang.toUpperCase(),
			projectId: this.config.projectId,
			instanceId: !!this.data ? this.data.instanceId : "",
			userId: !!this.data ? this.data.userId : "",
			userName: !!this.data ? this.data.userName : "",
			userEmail: !!this.data ? this.data.userEmail : "",
			userTimezone: !!this.data ? this.data.userTimezone : "",
		};

		this.sendMessage(psMessage);
		this.thankYouMsg = true;
	}

	changeStyle(event) {
		try {
			let toggleGroup = event.path[3].children;
			for (let item of toggleGroup) {
				item.firstChild.setAttribute(
					"style",
					`background-color: ${this.config.scoreBackgroundColor}; color: ${this.config.scoreTextColor}; border-color: ${this.config.scoreBorderColor}`,
				);
			}
			event.path[1].style["background-color"] =
				this.config.scoreCheckedBackgroundColor;
			event.path[1].style["color"] = this.config.scoreCheckedTextColor;
			event.path[1].style["border-color"] = this.config.scoreCheckedBorderColor;
		} catch (error) {}
	}
}
