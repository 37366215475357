import { NgModule, OnInit } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";

const appRoutes: Routes = [
	{ path: "**", redirectTo: "" },
	{ path: "", component: AppComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, { enableTracing: false }),
		FormsModule,
	],
	exports: [RouterModule],
})
export class AppRouting implements OnInit {
	router: any;
	routerSubscription: any;

	constructor() {}

	ngOnInit() {}
}
