<mat-sidenav-container class="bbwidget-container-master">
	<mat-sidenav-content class="bbwidget-bg-fullwidth" [style.--bg-color]="config.chatBackgroundColor">
		<ng-container *ngIf="chatView == 'BUBBLE'">
			<!-- crac7 -->
			<app-new-chat-section [helpResize]="helpResize" [isSoundNotication]="isSoundNotication"
				(toggleChat)="toggleChatSend($event)" (resetChat)="resetChat($event)" (clearRezise)="clearReziseSend($event)"
				(minimize)="minimizeSend()" (toogleNotification)="toogleNotification()"
				(changeEventOpenWelcome)="changeEventOpenWelcome($event)" [isOpenChat]="isOpenChat"
				[isShowWelcomeChat]="isShowWelcomeChat" (toogleSizeWidth)="clearReziseSend($event)" [greeting]="(list_message?.length > 0) ? false : true"
				#handle></app-new-chat-section>
		</ng-container>

		<!-- @if (chatView == 'WEB') {
		<div class="bbwidget-section-chat-header" [style.--toolbarChatContentColor]="
		config?.toolbarChatContentColor | safe: 'style'
	">

	 <button mat-button class="text-xl text-white rounded-full">
		 <mat-icon >chevron_left</mat-icon>
	 </button>


			<div class="flex">
				<mat-menu #emojiMenu="matMenu" yPosition="above" xPosition="before">
					<ng-container *ngIf="config.chatView !== 'WEB'">
						<button mat-menu-item (click)="toogleSizeScreen()">
							<mat-icon>
								{{ helpResize === "in" ? "fullscreen_exit" : "close_fullscreen" }}
							</mat-icon>
							<span>{{
								(helpResize === "in" ? "SCALE_UP" : "SCALE_DOWN") | translate
								}}</span>
						</button>
					</ng-container>
					@if (config?.notificationAudio) {
					<button mat-menu-item (click)="toogleSound()">
						<mat-icon>
							{{
							!isSoundNotication ? "notifications_active" : "notifications_off"
							}}
						</mat-icon>
						<span>
							{{
							(!isSoundNotication ? "TURN_ON_SOUND" : "MUTE") | translate
							}}</span>
					</button>
					}
					<button mat-menu-item (click)="cleanChat()">
						<mat-icon> restart_alt </mat-icon>
						<span>{{ "RESTART" | translate }}</span>
					</button>
				</mat-menu>
				<button mat-icon-button [matMenuTriggerFor]="emojiMenu" *ngIf="config.showToolbarMenu">
					<mat-icon>more_vert</mat-icon>
				</button>
				<button mat-icon-button (click)="close()" *ngIf="config.chatView == 'BUBBLE'">
					<mat-icon>keyboard_arrow_down</mat-icon>
				</button>
			</div>
		</div>
		} -->

		<chat-service-rating *ngIf="rateService" [config]="config"></chat-service-rating>

		<chat-support-intro [config]="config" [lang]="lang" (startChat)="startChat($event)" *ngIf="supportIntro">
		</chat-support-intro>
		<div class="items-start overflow-hidden bbwidget-content-all-chat"
			[ngStyle]="{'filter': (chatView == 'WEB' && list_message?.length == 0) ? 'blur(2px)' : 'none'}"
			[style.--chatBackgroundImage]="
				chatView == 'BANNER'
					? 'none'
					: 'url(' + config.chatBackgroundImage + ')'
			" [style.--chatBackgroundColor]="
				chatView == 'BANNER'
					? 'none'
					: (config.chatBackgroundColor | safe: 'style')
			">
			<ng-container *ngIf="chatView == 'BANNER'">
				<swiper effect="fade" class="absolute w-full h-full -z-10" #swiperHome [config]="configSwiper">
					<ng-container *ngFor="let slide of config.bannerSlides">
						<ng-template swiperSlide>
							<ng-container *ngIf="slide.type == 'image'">
								<div class="bbwidget-slide-banner" (click)="openPayload(slide.payload)"
									[class.cursor-pointer]="!!slide.payload" [ngStyle]="{
										'background-image': getCurrentSlideUrl(slide.url)
									}"></div>
							</ng-container>
							<ng-container *ngIf="slide.type == 'video'">
								<video control playsinline autoplay loop [muted]="true" class="object-cover w-full h-full">
									<ng-container *ngFor="let source of slide.sources">
										<source *ngIf="!!source.url" [src]="source.url" type="{{ source.type }}; codecs=&quot;{{
												source.codecs
											}}&quot;" />
									</ng-container>
								</video>
							</ng-container>
						</ng-template>
					</ng-container>
				</swiper>

				<div #bannerDialogue class="absolute flex flex-col w-[100vw] md:w-3/5 lg:w-2/5 px-[24px] py-[16px] text-white">
					<div class="mb-[8px] font-bold text-left md:text-[50px] lg:text-[60px] text-[40px]" style="line-height: 1">
						{{config.titleBanner}}
					</div>
					<div class="text-justify" style="font-size: 20px; line-height: 25px">
						{{config.subtitleBanner}}
					</div>
				</div>
			</ng-container>

			<div class="items-start bbwidget-chat-content" [class.degradado]="
					chatView == 'BANNER'
						? true
						: config.menuBottomView == 'STATIC_MENU' && menuBottom?.length > 0
				" [ngClass]="{
					'pt-[250px] w-full md:w-3/5 lg:w-2/5': chatView == 'BANNER'
				}" [style.--degradadoVariable1]="chatView == 'BANNER' ? '12%' : '10%'"
				[style.--degradadoVariable2]="chatView == 'BANNER' ? '80px' : '60px'" [style.--degradadoVariable3]="
					chatView == 'BANNER'
						? config.menuBottomView == 'STATIC_MENU' && menuBottom?.length > 0
							? '49%'
							: '55%'
						: '100%'
				" [style.--degradadoVariable4]="
					chatView == 'BANNER'
						? config.menuBottomView == 'STATIC_MENU' && menuBottom?.length > 0
							? '53%'
							: '57%'
						: '100%'
				" [ngStyle]="{
					'padding-bottom':
						chatView == 'BANNER'
							? config.menuBottomView == 'STATIC_MENU' && menuBottom?.length > 0
								? '20px'
								: 'auto'
							: 'none'
				}" id="scroll" #scroll *ngIf="!rateService && !supportIntro">
				<div class="w-full" [ngClass]="{
						'flex flex-col mt-auto': chatView == 'BANNER'
					}" [@listAnimation]="list_message.length" [class.bbwidget-chat-message-reverse]="config.isOffice"
					[ngStyle]="{ 'font-size': chatView == 'BANNER' ? '14px' : '16px' }">
					<div class="bbwidget-message-row" *ngFor="let list of list_message; index as i; let last = last"
						#messageContent [style.padding]="
							chatView == 'BANNER'
								? '0px 16px 16px'
								: '16px 16px ' +
									(config.menuBottomView == 'STATIC_MENU' &&
									menuBottom?.length > 0
										? '36px'
										: '16px')
						" [ngClass]="{
							'bbwidget-message-inverse': list.type_user === 'E',
							'mb-[45px]': last && chatView == 'BANNER'
						}">
						<div *ngIf="list.type_user === 'R'" class="flex justify-center animated zoomIn bbwidget-avatar"
							[style.--shadowColor]="config.shadowColor" [style.--avatarBackground]="config.avatarBackground">
							<img class="max-w-none" [src]="config.avatar" />
						</div>
						<div class="bbwidget-message-container">
							<div class="bbwidget-message-username" [style.--chatbotNameColor]="config.chatbotNameColor"
								*ngIf="!!config.chatbotName">
								{{ getMessageUsername(list.type_user) | translate }}
							</div>
							<ng-container>
								<div *ngFor="
										let message of list.response;
										index as j;
										let fist = first;
										let last = last
									" class="animated zoomIn max-w-[93%]" #messageResponse [ngClass]="{
										'mb-3': !last && list.response.length > 0,
										'bbwidget-bubble bbwidget-respone-text':
											message.type_user === 'R' &&
											message.visible &&
											message.type_message !== 'html',
										'bbwidget-bubble-inverse':
											message.type_user === 'E' &&
											message.type_message !== 'CO',
										'app-message': message.type_user === 'A',
										'bbwidget-bubble-inverse-image':
											message.type_user === 'E' && message.type_message === 'CO'
									}" [style.--userMessageBackgroundColorFirst]="
										config.userMessageBackgroundColorFirst | safe: 'style'
									" [style.--userMessageBackgroundColorSecond]="
										config.userMessageBackgroundColorSecond | safe: 'style'
									" [style.--userMessageBackgroundColorThird]="
										config.userMessageBackgroundColorThird | safe: 'style'
									" [style.--userMessageTextColor]="
										config.userMessageTextColor | safe: 'style'
									">
									<!-- Contenedor de texto aqui es todo jl -->
									<div class="bbwidget-message" *ngIf="message.type_message == 'TX'" matTooltipClass="mat-tooltip"
										[matTooltip]="message.date" matTooltipPosition="below">
										<div class="max-w-full bbwidget-message-text" [ngClass]="{
												'bbwidget-bubble-chat-question':
													message.type_user === 'R'
											}" [style.--botMessageBackgroundColor]="
												config.botMessageBackgroundColor | safe: 'style'
											" [style.--botMessageTextColor]="
												config.botMessageTextColor | safe: 'style'
											">
											<markdown clipboard [clipboardButtonComponent]="clipboardButton" class="variable-binding"
												[data]="message.text"></markdown>
										</div>
										<!-- <div class="bbwidget-message-time bbwidget-secondary-text">asdasd</div> -->
									</div>

									<!-- Contenedor de archivo (no imagen) -->
									<div class="bbwidget-message" *ngIf="message.type_message == 'FL'">
										<mat-icon>description</mat-icon>
										<div class="message" [innerHTML]="message.text"></div>
										<div class="bbwidget-message-time bbwidget-secondary-text">
											{{ message.date }}
										</div>
									</div>

									<!-- Contenedor de emoji -->
									<div class="bbwidget-message" *ngIf="message.type_message == 'EM'" matTooltipClass="mat-tooltip"
										[matTooltip]="message.date" matTooltipPosition="below">
										<!-- <div class="message" [innerHTML]="message.text"></div> -->
										<ngx-emoji set="apple" emoji="{{ message.text }}" size="24"></ngx-emoji>
									</div>

									<!-- Contenedor de imágenes -->
									<div class="bbwidget-message" style="overflow-y: hidden" *ngIf="message.type_message == 'CO'">
										<div *ngIf="message.content?.message.text">
											<div class="message">
												{{ messsage.content.message.text }}
											</div>
											<div class="bbwidget-message-time bbwidget-secondary-text">
												{{ message.date }}
											</div>
										</div>
										<img class="mb-[13px]" *ngIf="
												message.content.message.attachment &&
												message.content.message.attachment?.type == 'image'
											" alt="" [src]="
												message.content.message.attachment.payload.media[0].url
													| safe: 'resourceUrl'
											" />
									</div>

									<ng-container *ngIf="message.type_message == 'QR'">
										<chat-quick-response [message]="message" [config]="config" [chatView]="chatView"
											(sendMessage)="quickReplies($event)">
										</chat-quick-response>
									</ng-container>

									<!-- Contenedor reconexion agente humano -->
									<div *ngIf="message.type_message == 'AH'">
										<div style="
												display: flex;
												display: -ms-flexbox;
												justify-content: flex-start;
											">
											<div class="message">¿Deseas más información?</div>
										</div>
										<button mat-raised-button (click)="GetEmail()" style="
												margin: 8px 4px;
												border-radius: 6px;
												border: 1px solid #b7b7b7d1;
											">
											Chatea con un agente
										</button>
									</div>

									<div class="bbwidget-message" style="overflow-y: hidden" *ngIf="message.type_message == 'video'">
										<ng-container *ngFor="
												let media of message.text.message.attachment.payload
													.media
											">
											<video [id]="'videoResponse_' + i + '_' + j" playsinline class="w-full rounded-[10px] mb-[13px]"
												[controls]="media.controls" [autoplay]="media.autoplay" [muted]="media.muted"
												[preload]="media.preload" [poster]="media.poster" controlslist="nodownload">
												<ng-container *ngFor="let source of media.source">
													<source *ngIf="!!source.url" [src]="source.url" type="{{ source.type }}; codecs=&quot;{{
															source.codecs
														}}&quot;" />
												</ng-container>
											</video>
										</ng-container>
									</div>

									<!-- Contenedor template generico -->
									<div [ngClass]="{
											'bbwidget-generic-template-maximized': isMaximized,
											'w-[calc(40vw-85px)]': chatView == 'BANNER',
											'w-[calc(100vw-85px)]':
												chatView == 'BANNER' && dimensionActual == 'sm'
										}" *ngIf="message.type_message == 'PL'">
										<chat-carousel [config]="config" [chatView]="chatView" [dimensionActual]="dimensionActual"
											[elements]="
												message.payload.message.attachment.payload.elements
											" [presentationMode]="
												message.payload.message.attachment.payload
													.presentationMode
											" [onlyImage]="false" [helpResize]="helpResize">
										</chat-carousel>
									</div>

									<!-- Contenedor template para html -->
									<div class="bbwidget-bubble-chat-html" [style.--backgroundColor]="message.backgroundColor"
										*ngIf="message.type_message == 'html'">
										<div class="ql-snow">
											<div class="ql-editor" [innerHTML]="message.text | safe: 'html'"></div>
										</div>
									</div>

									<!-- Contenedor template buttons -->
									<div class="bbwidget-message" *ngIf="message.type_message == 'TB'">
										<div class="mb-[15px]" [ngClass]="{
												'w-[85%]': helpResize === 'in',
												'w-1/2': helpResize === 'out'
											}">
											<div class="text-center bbwidget-list-group-item bbwidget-first-child"
												[style.--headerButtonListBackgroundColorFirst]="
													config.headerButtonListBackgroundColorFirst
														| safe: 'style'
												" [style.--headerButtonListBackgroundColorSecond]="
													config.headerButtonListBackgroundColorSecond
														| safe: 'style'
												" [style.--headerButtonListBackgroundColorThird]="
													config.headerButtonListBackgroundColorThird
														| safe: 'style'
												" [style.--headerButtonListBorderColor]="
													config.headerButtonListBorderColor | safe: 'style'
												" [style.--headerButtonListTextColor]="
													config.headerButtonListTextColor | safe: 'style'
												">
												<div class="message">
													{{ message.payload.message.attachment.payload.text }}
												</div>
											</div>
											<button class="bbwidget-list-group-item button-list" mat-raised-button
												(click)="GenericButton(button)" *ngFor="
													let button of message.payload.message.attachment
														.payload.buttons;
													let isLast = last
												" [ngClass]="
													isLast
														? 'bbwidget-last-item-button-border'
														: 'bbwidget-last-item-button-without-border'
												">
												<img src="{{ button.image_url }}" alt="" *ngIf="button.image_url" class="w-5 h-5 m-[2px]" />
												{{ button.title }}
											</button>
										</div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>

					<div class="bbwidget-message-row" *ngIf="show_typing && isConnected" [style.padding]="
							chatView == 'BANNER'
								? '0px 16px 16px'
								: '16px 16px ' +
									(config.menuBottomView == 'STATIC_MENU' &&
									menuBottom?.length > 0
										? '36px'
										: '16px')
						">
						<div class="flex justify-center animated zoomIn bbwidget-avatar" [style.--shadowColor]="config.shadowColor"
							[style.--avatarBackground]="config.avatarBackground">
							<img class="max-w-none" [src]="config.avatar" />
						</div>
						<div class="bbwidget-message-container bbwidget-bubble bbwidget-respone-text">
							<div class="bbwidget-message-username" [style.--chatbotNameColor]="config.chatbotNameColor"
								*ngIf="!!config.chatbotName">
								{{ getMessageUsername("R") | translate }}
							</div>
							<div class="animated zoomIn">
								<div class="bbwidget-lds-ellipsis">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="bbwidget-connection-feedback animated" *ngIf="!isConnected && showFeedback">
					<button (click)="initSignalR()">
						<mat-icon>refresh</mat-icon>
						{{ "_CONECTION_FEEDBACK.RECONNECT" | translate }}
					</button>
				</div>
			</div>
			<!-- ----------------------------------------------------------------------------------------- -->

			<div id="bbwidget-confirmation-dialog" *ngIf="showModal">
				<div class="bbwidget-dialog-container">
					<h1 mat-dialog-title>
						{{ "_CONFIRMATION_DIALOG.TITLE" | translate }}
					</h1>
					<div class="bbwidget-dialog-content" mat-dialog-content>
						<p>{{ "_CONFIRMATION_DIALOG.CONTENT" | translate }}</p>
					</div>
					<div class="bbwidget-dialog-actions" mat-dialog-actions align="center">
						<button mat-button (click)="OnNoClick()" [ngStyle]="{
								'background-color': config.cancelButtonBackground,
								color: config.cancelButtonColor
							}">
							{{ "_CONFIRMATION_DIALOG.CANCEL" | translate }}
						</button>
						<button mat-button cdkFocusInitial (click)="EndChat()" [ngStyle]="{
								'background-color': config.confirmButtonBackground,
								color: config.confirmButtonColor
							}">
							{{ "_CONFIRMATION_DIALOG.CONFIRM" | translate }}
						</button>
					</div>
				</div>
			</div>

			<ng-container *ngIf="config.menuBottomView == 'STATIC_MENU' && menuBottom?.length > 0">
				<div class="relative w-full" [ngClass]="{
						'w-full md:w-3/5 lg:w-2/5 mb-[56px] m-2': chatView == 'BANNER',
						'w-full': chatView != 'BANNER'
					}">
					<div class="bbwidget-container-menu-bottom">
						<div class="bbwidget-container-menu-bottom-space">
							<div class="flex w-fit">
								<ng-container *ngFor="let button of menuBottom">
									<button mat-raised-button [matTooltip]="button.title" class="bbwidget-container-menu-bottom-buttons"
										(click)="quickReplies(button)">
										{{ button.title }}
									</button>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>

		<ng-container *ngIf="chatView == 'BANNER'">
			<div class="w-full md:w-3/5 lg:w-2/5 absolute bottom-[20px] px-2">
				<ng-container *ngTemplateOutlet="inputChat"> </ng-container>
			</div>
		</ng-container>

		<ng-container *ngIf="chatView == 'WEB'">
			<div [@inputAnimationWeb]="list_message?.length > 0"
				class="absolute w-2/5 left-2/4 -translate-x-2/4 -translate-y-2/4">
				<div class="px-4 mb-2 text-4xl font-bold">{{hiAmBotMessage}}</div>
				<div *ngIf="!rateService && !supportIntro" [ngClass]="{ top: !config.isOffice, bottom: config.isOffice}"
					[style.--inputBorderColor]="config.inputBorderColor | safe: 'style'"
					[style.--inputBackgroundColor]="config.inputBackgroundColor | safe: 'style'"
					[style.--inputTextColor]="config.inputTextColor | safe: 'style'"
					class="bbwidget-chat-footer animated zoomIn rounded-2xl p-4 pt-[15px]">
					<textarea class="resize-none bbwidget-input-light " [style.font-size]="chatView == 'BANNER' ? '14px' : '16px'"
						type="text" autocomplete="off" placeholder="{{ getPlaceholder() | translate }}" [(ngModel)]="inputMessage"
						(keyup.enter)="sendMessage()" (focus)="inputAnimation = true" (blur)="inputAnimationFuction()"></textarea>
					<button mat-icon-button style="background: #00000034" class="bbwidget-send-button" (click)="sendMessage()"
						[@inAnimation] *ngIf="inputMessage?.length > 0 && config.showSendButton">
						<ng-container *ngIf="!config?.sendIcon">
							<mat-icon [ngStyle]="{ color: config.sendButtonColor, top: '-2px' }">arrow_upward</mat-icon>
						</ng-container>
						<ng-container *ngIf="!!config?.sendIcon">
							<img [src]="config.sendIcon" alt="send icon" />
						</ng-container>
					</button>
				</div>
				<div class="bbwidget-container-menu-bottom-web">
					<div class="bbwidget-container-menu-bottom-space">
						<div class="flex w-fit">
							<ng-container *ngFor="let button of menuBottom">
								<button mat-raised-button [matTooltip]="button.title" class="bbwidget-container-menu-bottom-buttons"
									(click)="quickReplies(button)">
									{{ button.title }}
								</button>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="chatView == 'BUBBLE'">
			<ng-container *ngTemplateOutlet="inputChat"> </ng-container>
		</ng-container>
		<div class="bbwidget-spacer-spinner">
			<div class="flex items-center justify-center">
				<div class="bbwidget-spinner-sm" [hidden]="idleState != 'Reconectando'">
					<div class="bbwidget-bounce1"></div>
					<div class="bbwidget-bounce2"></div>
					<div class="bbwidget-bounce3"></div>
				</div>
			</div>
		</div>

		<!-- ----------------------------------------- -->
	</mat-sidenav-content>
</mat-sidenav-container>

<div id="makersclub-logo" *ngIf="config.showBrandbotLogo && chatView == 'BUBBLE'">
	<a href="https://www.makersclub.la/" rel="noopener" target="_blank">
		<img src="{{ brandbotLogo }}" alt="MakersClub logo" />
	</a>
</div>

<ng-template #inputChat>
	<div *ngIf="!rateService && !supportIntro" [@inputAnimation]="chatView == 'BANNER' ? inputAnimation : null"
		class="bbwidget-chat-footer animated zoomIn" [class.rounded-full]="chatView == 'BANNER'"
		[class.w-full]="chatView != 'BANNER'" [style.padding]="chatView == 'BANNER' ? '1px 16px' : '15px 16px 16px'"
		[ngClass]="{ top: !config.isOffice, bottom: config.isOffice }"
		[style.--inputBorderColor]="config.inputBorderColor | safe: 'style'"
		[style.--inputBackgroundColor]="config.inputBackgroundColor | safe: 'style'"
		[style.--inputTextColor]="config.inputTextColor | safe: 'style'">
		<input class="bbwidget-input-light" [style.font-size]="chatView == 'BANNER' ? '14px' : '16px'" type="text"
			autocomplete="off" placeholder="{{ getPlaceholder() | translate }}" [(ngModel)]="inputMessage"
			(keyup.enter)="sendMessage()" (focus)="inputAnimation = true" (blur)="inputAnimationFuction()" />
		<!--[disabled]="isDisabled()"-->

		<!-- EMOJIS -->
		<ng-container *ngIf="config.emojis">
			<mat-menu #emojiMenu="matMenu" yPosition="above" xPosition="before">
				<div id="emoji-container">
					<ngx-emoji sheetSize="64" set="apple" emoji="slightly_smiling_face" size="24"
						(emojiClick)="SendEmoji($event)"></ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="wink" size="24" (emojiClick)="SendEmoji($event)"></ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="heart_eyes" size="24"
						(emojiClick)="SendEmoji($event)"></ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="nerd_face" size="24"
						(emojiClick)="SendEmoji($event)"></ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="sunglasses" size="24"
						(emojiClick)="SendEmoji($event)"></ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="cry" size="24" (emojiClick)="SendEmoji($event)"></ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="sob" size="24" (emojiClick)="SendEmoji($event)"></ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="angry" size="24" (emojiClick)="SendEmoji($event)"></ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="hugging_face" size="24"
						(emojiClick)="SendEmoji($event)"></ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="robot_face" size="24"
						(emojiClick)="SendEmoji($event)"></ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="ok_hand" size="24" (emojiClick)="SendEmoji($event)"></ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="+1" size="24" (emojiClick)="SendEmoji($event)">
					</ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="-1" size="24" (emojiClick)="SendEmoji($event)">
					</ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="wave" size="24" (emojiClick)="SendEmoji($event)"></ngx-emoji>
					<ngx-emoji sheetSize="64" set="apple" emoji="heart" size="24" (emojiClick)="SendEmoji($event)"></ngx-emoji>
				</div>
			</mat-menu>
			<button mat-icon-button (click)="showEmoji = !showEmoji" *ngIf="!inputMessage" [matMenuTriggerFor]="emojiMenu">
				<mat-icon [ngStyle]="{ color: config?.colorIcon }">sentiment_satisfied_alt</mat-icon>
			</button>
		</ng-container>

		<!-- SEND BUTTON -->
		<button mat-icon-button style="background: #00000034" class="bbwidget-send-button" (click)="sendMessage()"
			[@inAnimation] *ngIf="inputMessage?.length > 0 && config.showSendButton">
			<ng-container *ngIf="!config?.sendIcon">
				<mat-icon [ngStyle]="{ color: config.sendButtonColor, top: '-2px' }">arrow_upward</mat-icon>
			</ng-container>
			<ng-container *ngIf="!!config?.sendIcon">
				<img [src]="config.sendIcon" alt="send icon" />
			</ng-container>
		</button>

		<!-- ATTACHMENT BUTTON -->
		<ng-container *ngIf="config?.uploadFileChat && !inputMessage">
			<button mat-icon-button (click)="MenuSelected('office', 'upload')">
				<mat-icon [ngStyle]="{ color: config?.colorIcon }">upload</mat-icon>
			</button>
		</ng-container>

		<ng-container *ngIf="config.menuBottomView == 'BURGER_MENU' && menuBottom?.length > 0">
			<button mat-icon-button [matMenuTriggerFor]="chatMenu" (menuOpened)="burgerMenu = true"
				(menuClosed)="burgerMenu = false; inputAnimationFuction()" [ngStyle]="{ color: config.sendButtonColor }">
				<mat-icon>menu</mat-icon>
			</button>
			<mat-menu #chatMenu="matMenu" yPosition="above" xPosition="before">
				<div class="bbwidget-list-group" *ngFor="let button of menuBottom">
					<button mat-menu-item class="menu-option" (click)="quickReplies(button)">
						{{ button.title }}
					</button>
				</div>
			</mat-menu>
		</ng-container>
	</div>
</ng-template>