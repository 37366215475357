import { Inject, Injectable } from "@angular/core";
import {
	BehaviorSubject,
	Observable,
	catchError,
	map,
	// mergeMap,
	// of,
} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class LanguageService {
	private _lang: BehaviorSubject<any | null> = new BehaviorSubject(null);
	urlParams = new URLSearchParams(window.location.search);
	private _apiUrl = environment.apiUrlPrefix;
	private localStorageKey: string = 'bb_widget_language';
	private hasChanged = false;

	constructor(
		private _httpClient: HttpClient) {
			// this._projectId = _config.projectId
		}

	get lang$(): Observable<any> {
		const language = localStorage.getItem(this.localStorageKey);
		if (language) {
      this._lang.next(language);
    }
		return this._lang.asObservable();
	}

	setLang(lang) {
		const projectId = localStorage.getItem('bbwidget-project_id')
		sessionStorage.setItem(`${this.localStorageKey}_${projectId}`, lang);
		this.setHasChaned(true);
		this._lang.next(lang);
	}

	setHasChaned(value: boolean): void {
		this.hasChanged = value;
	}

	getHasChanged(): boolean {
		return this.hasChanged;
	}

	getTranslation(lang = "de"): Observable<any> {
		if (!!localStorage.getItem('bbwidget-project_id')) {
			const params = {
				projectId: localStorage.getItem('bbwidget-project_id'),
				languageCode: lang,
			};
			return this._httpClient
				.get<any>(`${this._apiUrl}json-settings/get-language`, { params })
				.pipe(
					map((value) => {
						const { jsonString } = value;
						return JSON.parse(jsonString);
					}),
				);
			} 
			return
	}
}
