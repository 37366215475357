<div class="welcome-chat" *ngIf="options">
	<div class="welcome-chat__left">
		<div class="bbwidget-bubble animated zoomIn">
			<div
				class="flex justify-center background-header-icon"
				[style.--avatarBackground]="config?.avatarBackground"
				[style.--shadowColor]="config?.shadowColor"
			>
				<img class="max-w-none" [src]="config?.avatar" />
			</div>
			<div
				class="bbwidget-bubble-chat-question"
				[style.--botMessageBackgroundColor]="
					config.botMessageBackgroundColor | safe: 'style'
				"
				[style.--botMessageTextColor]="
					config.botMessageTextColor | safe: 'style'
				"
			>
				{{ "STRART_MESAGE_1" | translate }}
			</div>
			<div class="bbwidget-bubble-close" (click)="closeChatBuble()">
				<mat-icon
					class="flex justify-center items-center w-[18px] h-[18px] text-[10px]"
					>close</mat-icon
				>
			</div>
		</div>
	</div>
	<div
		class="welcome-chat__right"
		[ngClass]="{ 'bbwidget-bubble-horizontal': options?.length <= 2 }"
	>
		<div
			class="bbwidget-bubble animated zoomIn"
			[ngClass]="{ bubbleTwoNext: options?.length === 2 }"
			*ngFor="let item of options; index as i"
		>
			<button
				class="rounded-[20px] border-2 mb-2 border-[#b7b7b7d1] truncate w-fit max-w-full"
				mat-raised-button
				(click)="item?.onClickACtion(item.data)"
			>
				{{ item.title | translate }}
			</button>

			<!-- <app-bubble-actions [data]="item.title" /> -->
		</div>
	</div>
</div>

<!-- https://api.makersclub.brandbot.co/api/intents?projectId=Plugin%20Chatbot -->
