import {Component, input, Input, OnInit, Optional, ViewEncapsulation} from '@angular/core';
import { AppConfig } from "../../../../../app.config";
import { ClipboardButtonComponent } from '../../../../shared/components/clipboardButtonComponent/clipboardButtonComponent.component';
import { CommonModule } from "@angular/common";
import { MessageQR } from "../../../../core/interfaces/IQuickReply";
import { PayLoad } from "../../../../core/interfaces/IPayLoad";
import { MarkdownComponent } from "ngx-markdown";
import { SharedModule } from "../../../../shared/shared.module";

export interface Message {
	date: string;
	type_user: string;
	text: any;
	type_message: string;
	quick_reply: MessageQR;
	payload: PayLoad;
	content: PayLoad;
	visible: boolean;
	isSound: boolean;
}
@Component({
	selector: "chat-text-message",
	standalone: true,
	imports: [CommonModule, MarkdownComponent, SharedModule],
	templateUrl: "./text-message.component.html",
	styleUrl: "./text-message.component.css",
})
export class TextMessageComponent implements OnInit {

	public backgroundColor = input<string>() ;
	public textColor = input<string>();
	public message = input<Message>();
	public view = input<string>();
	public clipboardButton = input<ClipboardButtonComponent>;

	ngOnInit() {
		console.log(this.backgroundColor());
	}

	public validatedStyle() {
		if (!this.backgroundColor()) {
		}
	}

}
