import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'chat-input-chat',
  // standalone: true,
  // imports: [],
  templateUrl: './input-chat.component.html',
  styleUrl: './input-chat.component.css',
})
export class InputChatComponent {
  @Output() message: EventEmitter<any> = new EventEmitter<any>();

  answer: string = ''
  
  sendMessage() {
    this.message.emit(this.answer)
    this.answer = '';
  }

}
