import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class Global {
	channelId: string;
	socialId: string;
	restart: boolean;
	production: boolean;
	interaction: boolean;
	// isWeb: boolean = false;
	chatView: string;
	socketUrl: string;
	connectionAttempts: number = 0;
	appUrl: string = "";

	constructor() {
		const projectId = environment.projectId;
		// this.channelId = environment.projectId;
		this.restart = false;
		this.production = environment.production;
		this.interaction = false;
		// this.isWeb = config[projectId].isWeb;
		if (this.production) {
			// this.appUrl = `${config[projectId].webhooks.production.appUrl}/`;
		}
	}
}
