<div class="bbwidget-conversation-content">
	@for(list of list_message; track list; let i = $index; let last =$last) {
	<div class="bbwidget-message-block" [class.bbwidget-message-block-question]="list.type_user === 'E'">
		@if(list.type_user === "R") {
		<div class="bbwidget-avatar" [style.--shadowColor]="config.shadowColor"
			[style.--avatarBackground]="config.avatarBackground">
			<img class="" [src]="config.avatar" />
		</div>
		}

		<div class="bbwidget-message-list" [class.bbwidget-message-list-question]="list.type_user === 'E'">
			@for(message of list.response; track message; let j = $index; let first = $first; let last =$last) {
					@if (message.type_message == 'TX') {
						<div class="bbwidge-text-message" [class.bbwidge-text-message-question]="message.type_user === 'E'">
							{{message.text}}
						</div>
					}
	
					@if (message.type_message == 'QR') {
						<div class="bbwidget-container-menu-bottom-web">
							<div class="bbwidget-container-menu-bottom-space">
								<div class="bbwidget-button">
									@for(button of message.quick_reply.message.quick_replies; track button; let last = $last ) {
									<button (click)="quickReplies(button)" [ngStyle]="{'margin-right': !last ? '1rem': '0' }">
										{{ button.title }}
									</button>
									}
								</div>
							</div>
						</div>
					}
	
					@if (message.type_message == 'TB') {
						<div class="bbwidget-container-buttons-list">
							<div class="bbwidget-title-list">
							{{message.payload.message.attachment.payload.text}}
							</div>
							   @for (button of message.payload.message.attachment.payload.buttons; track button; let last = $last) {
								<button class="bbwidget-buttons-list" [class.bbwidget-border-radius-bottom]="last">
								{{ button.title }}
								</button>
							}
						</div>
					}

					@if (message.type_message == 'PL') {
						
					}

			}
		</div>

	</div>
	}
</div>
<div class="bbwidget-input-content" [@inputAnimationWeb]="list_message?.length > 0">
	<div class="bbwidget-first-message" [@fadeOut]="list_message?.length == 0">{{hiAmBotMessage}}</div>
	<chat-input-chat (message)="sendMessage($event)"></chat-input-chat>
	<div class="bbwidget-container-menu-bottom-web" [@fadeOut]="list_message?.length == 0">
		<div class="bbwidget-container-menu-bottom-space">
			<div class="bbwidget-button">
				@for(button of menuBottom; track button; let last = $last ) {
				<button (click)="quickReplies(button)" [ngStyle]="{'margin-right': !last ? '1rem': '0' }">
					{{ button.title }}
				</button>
				}
			</div>
		</div>
	</div>
</div>