import {
	animate,
	keyframes,
	state,
	style,
	transition,
	trigger,
} from "@angular/animations";
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ChangeDetectorRef,
	OnDestroy,
	Inject,
} from "@angular/core";
// import { GoogleAnalyticsService } from "ngx-google-analytics";
import { Global } from "../../../core/global-variables/global";
import { AppConfig } from "./../../../../../src/app.config";
import { ChatBoxService } from "../../../core/service/chatBox.service";
import { PreferenceService } from "../../../core/service/preference.service";
import { Subject, takeUntil } from "rxjs";

@Component({
	selector: "chat-widget",
	templateUrl: "widget.component.html",
	styleUrls: ["widget.component.css"],
	animations: [
		trigger("openClose", [
			state("true", style({ display: "block" })),
			state("false", style({ display: "none" })),
			transition("false => true", animate("100ms ease")),
			transition(
				"true => false",
				animate(
					"200ms ease-out",
					keyframes([style({ opacity: 1 }), style({ opacity: 0 })]),
				),
			),
		]),
		trigger("openCloseBtn", [
			state("true", style({ display: "flex" })),
			state("false", style({ display: "none" })),
			transition("true => false", [
				animate(
					"300ms ease-out",
					keyframes([
						style({ transform: "scale(1,1)" }),
						style({ transform: "scale(0,0)" }),
					]),
				),
			]),
			transition("false => true", [
				animate(
					"400ms ease-in",
					keyframes([
						style({ display: "block", transform: "scale(0,0)" }),
						style({ transform: "scale(1,1)" }),
					]),
				),
			]),
		]),
	],
})
export class WidgetComponent implements OnInit, OnChanges, OnDestroy {

	@Input() lang!: any;
	@Input() data!: any;
	@Input() isMinimizeChat: boolean;
	@Input() isOpenChat: boolean;
	@Input() isShowWelcomeChat: boolean;
	@Output("toggleChat") toggleChat: EventEmitter<any> = new EventEmitter<any>();
	@Output("minimize") minimize: EventEmitter<any> = new EventEmitter<any>();
	@Output("selectOption") selectOption: EventEmitter<any> =
		new EventEmitter<any>();

	showChat: boolean = false;
	showButton: any = true;
	isHomeChat = false
	isOnlyChat = true
	toggle: boolean = false;
	arrow: string = "chat_bubble";
	showChatWelcome: boolean = true;
	intervalId: any;
	showMessageRepit = false;
	showMessageRepitUltimate = true;
	countPlayAudio = 0;
	appUrl: string = "";
	config: AppConfig
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
		private _chatBoxService: ChatBoxService,
		private _preferenceService: PreferenceService,
		// private $gaService: GoogleAnalyticsService,
		private global: Global,
	) {
		// this.config = config;
		this.appUrl = this.global.appUrl;

		if (!sessionStorage.getItem('bbwidget_section')) {
			sessionStorage.setItem('bbwidget_section', 'HOME')
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!!changes.lang) {
			if (!!changes.lang.currentValue) this.lang = changes.lang.currentValue;
		}
		if (!!changes.isOpenChat) {
			this.isOpenChat = changes.isOpenChat.currentValue
		}
	}

	ngOnInit() {
		// REVISAR AL TERMINAR TODO LUIS DANIEL ALAVA TORRES
		// this.intervalId = setInterval(() => {
		// 	if (
		// 		!this.isMinimizeChat &&
		// 		!this.isShowWelcomeChat &&
		// 		this.showMessageRepitUltimate &&
		// 		!this.isOpenChat
		// 	) {
		// 		let audio: any = document.getElementById("saludo");
		// 		audio.muted = true;
		// 		this.countPlayAudio === 0 ? audio?.play() : audio?.stop();
		// 		this.countPlayAudio = this.countPlayAudio + 1;
		// 		this.showMessageRepit = true;
		// 	}
		// 	this._changeDetectorRef.detectChanges();
		// }, 60000);

		if ((sessionStorage.getItem('bbwidget_status') && sessionStorage.getItem('bbwidget_status') == 'OPEN') &&
			(sessionStorage.getItem('bbwidget_section') && sessionStorage.getItem('bbwidget_section') == 'HOME')) {
			this.openChat(this.isShowWelcomeChat ? 'chat_start' : 'chat_reopen_start')
		}

		this._preferenceService.config$
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((config: any) => {

			if (config) {
				this.config = config
			}


			this._changeDetectorRef.markForCheck();

		});

		this._chatBoxService.closeChat$.subscribe((close) => {
			if (close) {
				this.closeBubleChat()
			}
		})
	}

	closeMessageRepit() {
		this.showMessageRepit = false;
		this.showMessageRepitUltimate = false;
	}
	ngOnDestroy() {
		clearInterval(this.intervalId);
	}

	openChat(typeOpen) {
		this.showButton = false;
		this.isOpenChat = true
		this.isShowWelcomeChat = false;
		let isHomeChat: boolean, isOnlyChat: boolean
		this.showMessageRepit = false;
		// this.$gaService.event(typeOpen, "user_interactions", typeOpen);
		if (!!sessionStorage.getItem('bbwidget_section') && sessionStorage.getItem('bbwidget_section') == 'CHAT') {
			isHomeChat = false;
			isOnlyChat = true;
		} else {
			isHomeChat = true;
			isOnlyChat = false;
		}
		this.toggleChat.emit({ isHomeChat, isOnlyChat }); // aqui debe tomar de la configuracion del proyecto
		sessionStorage.setItem('bbwidget_status', 'OPEN');
	}

	closeBuble() {
		this.countPlayAudio = 0;
		this.isShowWelcomeChat = false;
		this.isOpenChat = false;
	}

	closeBubleChat() {
		this.isHomeChat = false
		this.isOnlyChat = true
		this.isOpenChat = false;
		this.isShowWelcomeChat = false;
		this.minimize.emit();
		this.showButton = true;
		this.countPlayAudio = 0;
		sessionStorage.setItem('bbwidget_status', 'CLOSE');
	}

	closeWelcomeChat(toogle) {
		this.showChatWelcome = toogle;
	}

	selectOptionWidget(data) {
		this.openChat("chat_start");
		this.selectOption.emit(data);
	}

	openOnlyHome() {
		this.showButton = !this.showButton;
		this.isShowWelcomeChat = false;
		this.isOpenChat = true;
		this.showMessageRepit = false;
		this.toggleChat.emit({ isHomeChat: true, isOnlyChat: false });
	}
}
