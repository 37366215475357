<div class="mb-[15px]" [ngClass]="{
												'w-[85%]': helpResize() === 'in',
												'w-1/2': helpResize() === 'out'
											}">
		<div class="text-center bbwidget-list-group-item bbwidget-first-child"
				 [style.--headerButtonListBackgroundColorFirst]="
													config().headerButtonListBackgroundColorFirst
														| safe: 'style'
												" [style.--headerButtonListBackgroundColorSecond]="
													config().headerButtonListBackgroundColorSecond
														| safe: 'style'
												" [style.--headerButtonListBackgroundColorThird]="
													config().headerButtonListBackgroundColorThird
														| safe: 'style'
												" [style.--headerButtonListBorderColor]="
													config().headerButtonListBorderColor | safe: 'style'
												" [style.--headerButtonListTextColor]="
													config().headerButtonListTextColor | safe: 'style'
												">
			<div>
				{{ message().payload.message.attachment.payload.text }}
			</div>
		</div>
		<button class="bbwidget-list-group-item button-list" mat-raised-button
						(click)="onButtonClicked(button)" *ngFor="
													let button of message().payload.message.attachment
														.payload.buttons;
													let isLast = last
												" [ngClass]="
													isLast
														? 'bbwidget-last-item-button-border'
														: 'bbwidget-last-item-button-without-border'
												">
			<img src="{{ button.image_url }}" alt="" *ngIf="button.image_url" class="w-5 h-5 m-[2px]"/>
			{{ button.title }}
		</button>
	</div>

