import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from "@angular/core";
import { SharedPropertiesGeneral } from "../../../core/service/sharedPropertiesGeneral";
import { Global } from "../../../core/global-variables/global";
import { AppConfig } from "./../../../../../src/app.config";
import { ChatBoxService } from "../../../core/service/chatBox.service";
import { PreferenceService } from "../../../core/service/preference.service";
import { Subject, takeUntil } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
	selector: "app-new-chat-section",
	templateUrl: "./new-chat-section.component.html",
	styleUrls: ["./new-chat-section.component.css"],
})
export class NewChatSectionComponent implements OnInit, OnChanges {
	@Output("toggleChat") toggleChat: EventEmitter<any> = new EventEmitter<any>();
	@Output("resetChat") resetChat: EventEmitter<any> = new EventEmitter<any>();
	@Output("toogleSizeWidth") toogleSizeWidth: EventEmitter<any> =
		new EventEmitter<any>();
	@Output("clearRezise") clearRezise: EventEmitter<any> =
		new EventEmitter<any>();
	@Output("minimize") minimize: EventEmitter<any> = new EventEmitter<any>();
	@Output("toogleNotification") toogleNotification: EventEmitter<any> =
		new EventEmitter<any>();
	@Input() isOpenChat: any = false;
	@Input() isShowWelcomeChat: boolean = true;
	@Output("changeEventOpenWelcome") changeEventOpenWelcome: EventEmitter<any> =
		new EventEmitter<any>();
	@Input() isSoundNotication: boolean;
	@Input() helpResize: string;

	@Input() greeting = true;

	backgroundHeader: string =
		"linear-gradient(135deg, rgb(27, 25, 140) 0%, rgb(42, 39, 218) 100%)";
	colotText: string = "white";
	config: any = null;
	isResizeWidth = false;
	image = null;
	appUrl: string = "";
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	formCustom: FormGroup;
	// chatView = mainConfig.chatView;

	constructor(
		private _formBuilder: FormBuilder,
		private _chatBoxService: ChatBoxService,
		private global: Global,
		public _sharedPropertiesGeneral: SharedPropertiesGeneral,
		private _preferenceService: PreferenceService,
		private _changeDetectorRef: ChangeDetectorRef
	) {

		this.appUrl = this.global.appUrl;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('greeting' in changes) {
			this.greeting = changes.greeting.currentValue;
		}
	}

	ngOnInit(): void {

		this.formCustom = this._formBuilder.group({
			prefillData: ["{'3F0DBE40-F816-46CE-9F3C-A41ED5E2C7A2': 3F0DBE40-F816-46CE-9F3C-A41ED5E2C7A2, '8685E29C-2904-4072-BE6C-042CD3FC969A': '22.10.2024', '073659A3-F60C-4EEE-8808-8CBE9A531022': 'Chip123'}"]
		})
		// "3F0DBE40-F816-46CE-9F3C-A41ED5E2C7A2": "c",
		// "8685E29C-2904-4072-BE6C-042CD3FC969A": "22.10.2024",
		// "073659A3-F60C-4EEE-8808-8CBE9A531022": "Chip123"

		this._preferenceService.config$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config: any) => {

				if (config) {
					this.config = config
				}


				this._changeDetectorRef.markForCheck();

			});

	}

	goBack() {
		this.clearRezise.emit(false);
		this.isResizeWidth = false;
		this.toggleChat.emit({ isHomeChat: true, isOnlyChat: false });
		sessionStorage.setItem('bbwidget_section', 'HOME');
	}

	toogleSizeScreen() {
		this.toogleSizeWidth.emit(this.helpResize == 'in' ? true : false);
		this.isResizeWidth = this.helpResize !== "in";
	}

	toogleSound() {
		//	this.isSoundNotication = !this.isSoundNotication;
		// localStorage.setItem('isSoundActive',String(this.isSoundNotication));
		this.toogleNotification.emit();
	}
	cleanChat() {
		this.resetChat.emit();
	}

	close() {
		this._chatBoxService.closeChat();
		// this.minimize.emit();
		// const isOpenChat = false;
		// const isShowWelcomeChat = false;
		// this.changeEventOpenWelcome.emit({ isOpenChat, isShowWelcomeChat });
	}

	// sendMessageTest() {
	// 	this.formCustom
	// 	this._preferenceService.testQuestion().subscribe()
	// }
}
