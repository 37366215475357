import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { environment } from '../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {

  // private _preference: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _config: BehaviorSubject<any | null> = new BehaviorSubject(null);

  // get preference$(): Observable<any> {
  //   return this._preference.asObservable();
  // }

  get config$(): Observable<any> {
    return this._config.asObservable();
  }

  set config(config: any) {
    this._config.next(config)
  }

  _apiUrl = environment.apiUrlPrefix;

  constructor(
    public _httpClient: HttpClient) {}

  getPreferences(projectId: string, module: string): Observable<any> {
    const params = {
      projectId,
      module,
    };

    localStorage.setItem('bbwidget-project_id', projectId)

    return this._httpClient
      .get<any>(`${this._apiUrl}json-settings/get-preferences`, { params })
      .pipe(
        map((response) => {
          const { jsonString } = response;
          const config = JSON.parse(jsonString);

          config['projectId'] = projectId;
          config['chatView'] = module.toUpperCase();

          this._config.next(config);
          return config
        }),
        catchError((err) => {
          return err;
        })
      );
  }

  // getProjectId(): Observable<any> {
  //   return this._httpClient.get<any>('assets/config.json')
  //     .pipe(
  //       map((response) => {
  //         const { projectId } = response;
  //         return projectId
  //       }),
  //       catchError(() => {
  //         return null
  //       })
  //     )
  // }
}
