<div class="bbwidget-web-container">
	<div class="bbwidget-web-header-buttons">
		<div class="bbwidget-web-chatbot-name">
			{{config.chatbotName}}
		</div>
		<app-language></app-language>
	</div>
	<div class="bbwidget-web-content-chat">
		<chat-conversation-chat [global]="global"></chat-conversation-chat>
	</div>
</div>

<!-- <div class="w-full bbwidget-container-master full-container" [style.--backgroundWeb]="config.backgroundWeb">
	<div class="flex items-center justify-center w-full h-full mx-auto my-0">
		<mat-card class="w-full h-full md:w-[80%] p-0 full-size">
			
			<div *ngIf="isHomeChat" class="bbwidget-container-home">
				<app-home-chat [config]="config" [global]="global" (toggleChat)="toggleChat($event)" style="overflow: hidden"
					(toogleSizeWidth)="resize()"></app-home-chat>
			</div>

			<ng-container *ngIf="showChat">
				<chat-chat-box [global]="global" style="height: 100%;" 
				[helpResize]="helpResize"
				[isMinimizeChat]="isMinimizeChat"
				(isShowWelcomeChat)="isShowWelcomeChat = false"
				(toggleChat)="toggleChat($event)"
				(clearRezise)="clearRezise($event)"
				(minimize)="minimize()"></chat-chat-box>
			</ng-container>
		</mat-card>
	</div>
</div> -->