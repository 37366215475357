import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConfig } from '../../../../../src/app.config';

@Component({
  selector: 'chat-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input() config: AppConfig;
  @Output("changeSection") changeSection: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  changeSectionSend(event: any) {
    this.changeSection.emit(event)
  }
}
