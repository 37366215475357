import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { languages } from "./language.data";
import { TranslateService } from "@ngx-translate/core";
import { ChatBoxComponent } from "../chat/components/chat-box/chat-box.component";
import { LanguageService } from "../core/service/language/language.service";
// import { ChatBoxBannerComponent } from "../chat/components/chat-box-banner/chat-box-banner.component";

@Component({
	selector: "app-language",
	templateUrl: "./language.component.html",
	styleUrls: ["./language.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageComponent implements OnInit {
	@ViewChild(ChatBoxComponent) chatBoxChield: ChatBoxComponent;
	// @ViewChild(ChatBoxBannerComponent) chatBoxChield: ChatBoxBannerComponent;

	langs = languages;
	langSelected: string;

	menuVisible = false;
	constructor(
		private _translateService: TranslateService,
		private _languageService: LanguageService,
		// private _chatBoxComponent: ChatBoxComponent,
		private _changeDetectorRef: ChangeDetectorRef,
	) {}


  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

	@HostListener('document:click', ['$event'])
  closeMenu(event: MouseEvent) {
    const clickedInside = (event.target as HTMLElement).closest('.menu') !== null;
    const clickedButton = (event.target as HTMLElement).closest('button') !== null;

    if (!clickedInside && !clickedButton) {
      this.menuVisible = false;
    }
  }

	ngOnInit(): void {
		this.langSelected = this._translateService.currentLang;
		this._translateService.use(this.langSelected);
	}

	changeLang(lang: string): void {
		this.langSelected = lang;
		this._translateService.use(this.langSelected);
		this._translateService.setDefaultLang(this.langSelected);
		this._languageService.setLang(this.langSelected);
		this._changeDetectorRef.markForCheck()
		// this._chatBoxComponent.RestartChat();
		// this.globalChangeLanguage(lang);
	}

	globalChangeLanguage(lang: string) {
		// const event: CustomEvent = new CustomEvent('changeLanguageEvent', {});
		// document.dispatchEvent(event);
	}
}
