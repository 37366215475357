<div
	class="flex flex-row-reverse items-center justify-between px-2 transition-all bg-[#282923] border-gray-300 rounded-sm h-7 hover:bg-[#282923]"
>
	<!-- icon copy -->
	<div class="py-4 cursor-pointer contents" (click)="onClick()">
		<svg
			width="18"
			height="18"
			viewBox="0 0 76 88"
			fill="#f0f0f0"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M56 0H8C3.6 0 0 3.6 0 8V64H8V8H56V0ZM68 16H24C19.6 16 16 19.6 16 24V80C16 84.4 19.6 88 24 88H68C72.4 88 76 84.4 76 80V24C76 19.6 72.4 16 68 16ZM68 80H24V24H68V80Z"
			/>
		</svg>
	</div>

	@if (isVisible()) {
		<div class="flex px-2 text-gray-100 right-9 -top-[1.60rem]">
			Copied
		</div>
	}
</div>
