import { Injectable } from "@angular/core";

@Injectable()
export class Menu {
	options: any[];

	constructor() {
		this.options = [
			{
				name: "Mi Cuenta",
				children: [
					// { type: "O", name: "Cerrar chat", action: "CerrarChat", icon: "cancel", active: true },
					{
						type: "O",
						name: "Expandir",
						action: "ExpandirChat",
						icon: "open_in_full",
						active: true,
					},
					{
						type: "O",
						name: "Contraer",
						action: "ContraerChat",
						icon: "close_fullscreen",
						active: false,
					},
					{
						type: "O",
						name: "Reiniciar",
						action: "BorrarChat",
						icon: "restart_alt",
						active: true,
					},
					// { type: "T", name: "Desvincular Equipo", action: "trans|11100", icon: "mic_external_off", active: true },
					// { type: "T", name: "Cerrar Sesión", action: "trans|11110", icon: "do_not_disturb_on", active: true },
					// { type: "O", name: "Chatea con un Agente", action: "AgenteChat", active: true }
				],
			},
			{
				name: "Mis Saldos",
				children: [
					{ type: "T", name: "Saldos", action: "trans|90000", active: true },
				],
			},
			{
				name: "Otros",
				children: [
					{ type: "T", name: "Bloqueos", action: "info|872063", active: true },
					{
						type: "T",
						name: "(On/Off) Tarjeta Débito",
						action: "trans|99000",
						active: true,
					},
					{
						type: "T",
						name: "ATM o Agencia cercana",
						action: "info|999991",
						active: true,
					},
				],
			},
		];
	}
}
