import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	signal,
	ViewEncapsulation,
} from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
// import { GoogleAnalyticsService } from "ngx-google-analytics";
import { AppConfig } from "../../../../../src/app.config";
import { Global } from "../../../../../src/app/core/global-variables/global";
import {
	animate,
	keyframes,
	state,
	style,
	transition,
	trigger,
} from "@angular/animations";
import { PreferenceService } from "../../../core/service/preference.service";
import { Subject, takeUntil } from "rxjs";

@Component({
	selector: "chat-bubble-chat",
	templateUrl: "./bubble-chat.component.html",
	styleUrls: ["./bubble-chat.component.css"],
	animations: [
		trigger("resizeAnimation", [
			state(
				"in",
				style({ width: "{{width}}", height: "{{height}}", maxHeight: "600px" }),
				{ params: { width: "370px", height: "84vh" } },
			),
			state(
				"out",
				style({ width: "688px", height: "84vh", maxHeight: "600px" }),
			),
			transition("in => out", [animate("0.15s ease-in")]),
			transition("out => in", [animate("0.15s ease-out")]),
		]),
		trigger("openClose", [
			state("true", style({ display: "block" })),
			state("false", style({ display: "none" })),
			transition("false => true", animate("100ms ease")),
			transition(
				"true => false",
				animate(
					"200ms ease-out",
					keyframes([style({ opacity: 1 }), style({ opacity: 0 })]),
				),
			),
		]),
	],
})
export class BubbleChatComponent implements OnInit {
	@Input() global: Global;

	// new implementation
	public showButton = signal<boolean>(true);
	//========
	isHomeChat: boolean;
	showChat: boolean;
	isShowWelcomeChat: boolean = true;
	device: any;
	helpResize = "in";
	isMaximized = false;
	isMinimizeChat = false;
	isOpenChat = false;
	config: AppConfig;
	firstTime = true;

	width: string = "370px";
	height: string = "84vh";

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _preferenceService: PreferenceService,
		private _deviceDetectorService: DeviceDetectorService,
		// private $gaService: GoogleAnalyticsService,
		// private _changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.device = this._deviceDetectorService.getDeviceInfo();
		if (
			sessionStorage.getItem("bbwidget_section") &&
			sessionStorage.getItem("bbwidget_section") == "CHAT"
		) {
			this.isHomeChat = false;
			if (
				sessionStorage.getItem("bbwidget_status") &&
				sessionStorage.getItem("bbwidget_status") == "OPEN"
			) {
				this.toggleChat({
					isOnlyChat: true,
					isHomeChat: false,
				});
			}
		}

		this._preferenceService.config$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config: any) => {
				this.config = config;

				if (this.firstTime) {
					const chatView = config.chatView;
					if (window.innerWidth < 480) {
						if (chatView != "BUBBLE") {
							this.width = window.innerWidth.toString() + "px";
							this.height = "auto";
						} else {
							this.width = "320px";
						}
					}

					this.firstTime = false;
				}
			});
	}

	minimize() {
		this.helpResize = "in";
		this.isOpenChat = false;

		this.toggleChat({ isHomeChat: false, isOnlyChat: false });
	}

	toggleChat(eventEmitted) {
		this.isMinimizeChat = eventEmitted?.isOnlyChat;
		this.isHomeChat = eventEmitted?.isHomeChat;
		this.showChat = eventEmitted?.isOnlyChat;
		if (this.showChat && this.device.deviceType == "desktop") {
			this.helpResize = "out";
		}

		console.log("toggleChat");
	}

	resize() {
		this.helpResize = this.helpResize === "out" ? "in" : "out";
		if (this.helpResize === "out") {
			// this.$gaService.event("chat_expand", "user_events", "chat_expand");
		}
		this.isMaximized = !this.isMaximized;
		console.log("resize");
	}

	clearRezise(change: boolean) {
		this.helpResize = change ? "out" : "in";
		this.isMaximized = change;

		this.showButton.set(false);
	}
}
