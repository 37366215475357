import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, Inject, Injector, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { AppRouting } from "./app.routing.module";
import { ChatBoxComponent } from "./chat/components/chat-box/chat-box.component";
import { createCustomElement } from "@angular/elements";

import { HttpClient } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { ChatModule } from "./chat/chat.module";
import { MaterialModule } from "./material/material.module";
import { ChatBotModule } from "../@chatBot/chatbot.module";
import { LanguageService } from "./core/service/language/language.service";
import { CoreModule } from "./core/core.module";
import { MarkdownModule } from "ngx-markdown";


const keyGoogleAnalytics = ''; //config[projectId].keyGoogleAnalytics;

@NgModule({
	declarations: [AppComponent],
	imports: [
		MaterialModule,
		CoreModule,
		ChatModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRouting,
		MarkdownModule.forRoot(),
		// NgxGoogleAnalyticsModule.forRoot(keyGoogleAnalytics),
		// NgxGoogleAnalyticsRouterModule,
		ChatBotModule,
		TranslateModule.forRoot(
			{
			loader: {
				provide: TranslateLoader,
				useClass: LanguageService,
				deps: [HttpClient],
			},
		}
	)
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
	constructor(
		private injector: Injector,
	) {
		const el = createCustomElement(AppComponent, {
			injector: this.injector,
		});

		if (!customElements.get("brandbot-widget")) {
			customElements.define("brandbot-widget", el);
		}
	}
	ngDoBootstrap() {}
}
