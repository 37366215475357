import { Injectable } from "@angular/core";

@Injectable()
export class PlaceHolders {
	values: any[];

	constructor() {
		this.values = [
			"Escribe aquí: Quiero comprar un carro",
			"Escribe aquí: ¿Cómo se llena un cheque?",
			"Escribe aquí: ¿Dónde puedo sacar plata?",
			"Escribe aquí: Quiero abrir una cuenta de ahorro",
			"Escribe aquí: Quiero una mastercard debit",
			"Escribe aquí: Necesito dinero en efectivo",
		];
	}
}
