import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { AppConfig } from "../../../../../src/app.config";
import { Global } from "../../../../../src/app/core/global-variables/global";
import { DeviceDetectorService } from 'ngx-device-detector';
// import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { PreferenceService } from '../../../core/service/preference.service';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'chat-web-chat',
  templateUrl: './web-chat.component.html',
  styleUrls: ['./web-chat.component.css']

})
export class WebChatComponent implements OnInit {
  @Input() global: Global;
  isHomeChat: boolean = true;
  showChat: boolean;
	isShowWelcomeChat: boolean = true
	device: any;
  helpResize = "in";
  isMaximized = false;
	isMinimizeChat = false;
	isOpenChat = false;
	config: AppConfig
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _deviceDetectorService: DeviceDetectorService,
    // private $gaService: GoogleAnalyticsService,
    private _preferenceService: PreferenceService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.device = this._deviceDetectorService.getDeviceInfo();
    if ((sessionStorage.getItem('bbwidget_section') && sessionStorage.getItem('bbwidget_section') == 'CHAT')) {
			this.isHomeChat = false;
			this.showChat = true;
			if ((sessionStorage.getItem('bbwidget_status') && sessionStorage.getItem('bbwidget_status') == 'OPEN')) {
				this.toggleChat({
					isOnlyChat: true,
					isHomeChat: false
				})
			}
		}

    this._preferenceService.config$
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((config: any) => {
			this.config = config
			this._changeDetectorRef.markForCheck();
		});
  }

  minimize() {
		this.helpResize = "in"
		this.isOpenChat = false
		this.toggleChat({ isHomeChat: false, isOnlyChat: false });
	}

  toggleChat(eventEmitted) {
		this.isMinimizeChat = eventEmitted?.isOnlyChat;
		this.isHomeChat = eventEmitted?.isHomeChat;
		this.showChat = eventEmitted?.isOnlyChat;
		if (this.showChat && this.device.deviceType == 'desktop') {
			this.helpResize = 'out';
		}
	}

  resize() {
		this.helpResize = this.helpResize === "out" ? "in" : "out";
		if (this.helpResize === "out") {
			// this.$gaService.event("chat_expand", "user_events", "chat_expand");
		}
		this.isMaximized = !this.isMaximized;
	}

  clearRezise(change: boolean) {
		this.helpResize = change ? 'out': "in";
		this.isMaximized = change;
	}

}
