<!-- [config]="config" -->

<chat-widget
	(toggleChat)="toggleChat($event)"
	[isMinimizeChat]="isMinimizeChat"
	[isOpenChat]="isOpenChat"
	[isShowWelcomeChat]="isShowWelcomeChat"
	(minimize)="minimize()"
	(selectOption)="toggleChat({ isHomeChat: false, isOnlyChat: true })"
>
</chat-widget>


<div class="bbwidget-chat-box-container">
	<div
		*ngIf="isHomeChat"
		[@resizeAnimation]="helpResize"
		[ngClass]="{
			'bbwidget-brandbot-widget-left': config.position == 'left',
			'bbwidget-brandbot-widget-right': config.position == 'right'
		}"
	>
		<app-home-chat
			(handleClicked)="onHandleClicked($event)"
			[config]="config"
			[global]="global"
			(toggleChat)="toggleChat($event)"
			style="overflow: hidden"
			(toogleSizeWidth)="resize(event)"
		></app-home-chat>
	</div>

	<ng-container *ngIf="showChat">
		<div
			[@resizeAnimation]="helpResize"
			[@openClose]="showChat"
			[ngClass]="{
				'bbwidget-brandbot-widget-left': config.position == 'left',
				'bbwidget-brandbot-widget-right': config.position == 'right'
			}"
		>
			<chat-chat-box
				[global]="global"
				[helpResize]="helpResize"
				[isMinimizeChat]="isMinimizeChat"
				(isShowWelcomeChat)="isShowWelcomeChat = false"
				(toggleChat)="toggleChat($event)"
				(clearRezise)="clearRezise($event)"
				(minimize)="minimize()"
			></chat-chat-box>
		</div>
	</ng-container>
</div>
