import { NgModule } from "@angular/core";
import { DescribeLangPipe } from "./pipes/describe-lang.pipe";
import { LinkifyPipe } from "./pipes/linkify.pipe";
import { SafePipe } from "./pipes/safe.pipe";

@NgModule({
	declarations: [LinkifyPipe, SafePipe, DescribeLangPipe, ],
	exports: [LinkifyPipe, SafePipe, DescribeLangPipe],
})
export class SharedModule {}
