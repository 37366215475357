import { Injectable, EventEmitter } from "@angular/core";
import {
	HttpTransportType,
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
} from "@microsoft/signalr";
import { Global } from "../global-variables/global";
import { PsMessage } from "../interfaces/IMessage";

@Injectable({
	providedIn: "root",
})
export class SignalRService {
	private _hubConnection: HubConnection;
	public isLoading: Boolean;

	public messageReceived: EventEmitter<PsMessage>;
	public connectionEstablished: EventEmitter<Boolean>;
	public connectionFailed: EventEmitter<Boolean>;
	public onRestart: EventEmitter<any>;

	constructor(private global: Global) {
		this.connectionEstablished = new EventEmitter<Boolean>();
		this.connectionFailed = new EventEmitter<boolean>();
		this.messageReceived = new EventEmitter<PsMessage>();
		this.onRestart = new EventEmitter<any>();
		this.isLoading = true;
	}

	public initConnection() {
		this._hubConnection = new HubConnectionBuilder()
			.withUrl(`${this.global.socketUrl}/chat`, {
				skipNegotiation: true,
				transport: HttpTransportType.WebSockets,
			})
			.withAutomaticReconnect() // Habilitar reconexión automática
			.build();

		this.registerOnServerEvents();
		this.startConnection();
	}

	public sendMessage(message: any) {
		this._hubConnection
			.invoke("SendPSMessageToServer", JSON.stringify(message))
			.catch((error: any) => {
				console.error(error);
				this.global.connectionAttempts = 3;
			});
		if (message.Content !== "INICIO") {
			this.global.interaction = true;
		}
	}

	notificationError() {
		const psMessage: PsMessage = {
			socialId: "",
			socialName: "",
			type: "text",
			content: "No se pudo entregar tu mensaje, por favor inténtalo más tarde",
			channelAccount: "",
			channelId: "",
			languageId: "1",
		};
		this.messageReceived.emit(psMessage);
	}

	public startConnection(): void {
		this.global.connectionAttempts++;
		this._hubConnection
			.start()
			.then((data: any) => {
				this.connectionEstablished.emit(true);
				this.isLoading = false;
			})
			.catch((error: any) => {
				this.connectionFailed.emit(true);
				if (this.global.connectionAttempts < 1) {
					this.startConnection();
				}
			});

		this._hubConnection.onreconnecting((error) => {
			console.warn(`SignalR reconectando: ${error}`);
			this.isLoading = true;
		});

		this._hubConnection.onreconnected((connectionId) => {
			console.log(`SignalR reconectado: ${connectionId}`);
			this.connectionEstablished.emit(true);
			this.isLoading = false;
		});
	}

	public removeConnection(): void {
		this._hubConnection.stop();
	}

	public registerOnServerEvents(): void {
		this._hubConnection.on("Send", (message) => {
			this.messageReceived.emit(message);
		});
	}
}
