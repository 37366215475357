import {
	Component,
	Output,
	EventEmitter,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	OnInit,
	Input,
} from "@angular/core";
import {
	trigger,
	state,
	style,
	transition,
	animate,
	stagger,
} from "@angular/animations";
import { SharedPropertiesGeneral } from "../../../core/service/sharedPropertiesGeneral";
@Component({
	selector: "chat-upload-file",
	templateUrl: "./upload-file.component.html",
	styleUrls: ["./upload-file.component.scss"],
	animations: [
		trigger("fadeInOut", [
			state(
				"in",
				style({ backgroundColor: "#f5f3f3", borderColor: "#dddddd" }),
			),
			// transition("out => in", animate(600)),
			transition("in <=> out", animate(400)),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFileComponent implements OnInit {
	// draggedFile: File;
	state = "out";
	attachments: any[];
	showAttachment: boolean = true;
	@Input() config: any;
	@Output("uploadField") uploadField: EventEmitter<any> =
		new EventEmitter<any>();

	files: any[] = [];
	fileSelector = [];
	fileSelected = "";

	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
		public _sharedPropertiesGeneral: SharedPropertiesGeneral,
	) {}

	ngOnInit(): void {}

	dragOver(event: DragEvent) {
		this.state = "in";
		event.preventDefault();
		this._changeDetectorRef.markForCheck;
	}

	// drop(event: DragEvent) {
	// 	this.state = "in";
	// 	event.preventDefault();
	// 	console.log(event);
	// 	this.draggedFile = event.dataTransfer.files[0];
	// }

	dragLeave(event: DragEvent) {
		event.preventDefault();
		this.state = "out";
		this._changeDetectorRef.markForCheck;
	}

	/**
	 * on file drop handler
	 */
	onFileDropped($event: DragEvent) {
		$event.preventDefault();
		const files = $event.dataTransfer.files;
		this.prepareFilesList(files);
	}

	/**
	 * handle file from browsing
	 */
	fileBrowseHandler(files, target) {
		this.prepareFilesList(files);
	}

	/**
	 * Delete file from files list
	 * @param index (File index)
	 */
	deleteFile(index: number) {
		this.files.splice(index, 1);
	}

	/**
	 * Simulate the upload process
	 */
	uploadFilesSimulator(index: number) {
		setTimeout(() => {
			if (index === this.files.length) {
				return;
			} else {
				const progressInterval = setInterval(() => {
					if (this.files[index].progress === 100) {
						clearInterval(progressInterval);
						this.uploadFilesSimulator(index + 1);
					} else {
						this.files[index].progress += 5;
					}
				}, 200);
			}
		}, 1000);
	}

	/**
	 * Convert Files list to normal array list
	 * @param files (Files List)
	 */
	prepareFilesList(files: any) {
		for (const item of files) {
			item.progress = 0;
			this.files.push(item);
		}
		this.uploadField.emit(this.files);
		this.files = [];

		// this.uploadFilesSimulator(0);
	}

	selectAttachmentFile() {
		if (this.fileSelected !== "") {
			this.files.push(this.fileSelected);
			this.uploadField.emit(this.files);
			this.files = [];
			this.fileSelector = [];
			this.fileSelected = "";
		}
	}

	/**
	 * format bytes
	 * @param bytes (File size in bytes)
	 * @param decimals (Decimals point)
	 */
	formatBytes(bytes, decimals) {
		if (bytes === 0) {
			return "0 Bytes";
		}
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals || 2;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
	}
}
