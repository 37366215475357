<ng-container *ngIf="presentationMode == 'carousel'">
	<!-- <div #heightCalculation class="swiper-height-calculator"> -->
	<swiper [ngClass]="{'pb-[9px]': elements.length <= 2,'pb-[30px]': elements.length > 2 }" effect="fade" #swiperCarousel
		[config]="configSwiper">
		<ng-container *ngFor="let card of elements">
			<ng-template swiperSlide>
				<!-- <div class="w-72"> -->
				<ng-container *ngTemplateOutlet="cartTemplate; context: {$implicit: card}">
				</ng-container>
				<!-- </div> -->
			</ng-template>
		</ng-container>
	</swiper>
	<!-- </div> -->
</ng-container>


<ng-container *ngIf="presentationMode == null || presentationMode == 'grid'">
	<div class="grid grid-cols-1 gap-7 mb-[13px]"
	[ngClass]="{'sm:grid-cols-2 lg:grid-cols-3': chatView != 'BUBBLE', 'grid-cols-1 sm:grid-cols-2': (chatView == 'BUBBLE' && helpResize == 'out')}">
	<div style="padding: 0px 1px 0px 0px; margin-bottom: 15px;" *ngFor="let card of elements">
		<ng-container *ngTemplateOutlet="cartTemplate; context: {$implicit: card}">
		</ng-container>
	</div>
</div>
</ng-container>


<ng-template #cartTemplate let-card>
	<mat-card class="bbwidget-card-container" [ngClass]="{
		'w-[288px]': chatView != 'BANNER',
		'w-[100%]': chatView == 'BANNER',
		'w-full': chatView == 'BANNER' && dimensionActual == 'sm' 
	}">
		<img class="bbwidget-mat-card-image-custom" alt="" src="{{card.image_url}}">
		<div class="mb-2 border-b-2 divide-y"></div>
		<mat-card-content class="h-auto px-4">
			<div class="bbwidget-mat-card-title">
				{{card.title}}</div>
			<div *ngIf="card?.subtitle?.length > 0 " class="bbwidget-mat-card-subtitle">
				{{card.subtitle}}</div>
		</mat-card-content>
		<mat-card-actions class="pt-0">
			<button class="bbwidget-btn-footer-pl" *ngFor="let button of card.buttons; let last = last"
				(click)="openUrl(button)" mat-button [ngClass]="{'mb-2': !last, 'mb-4': last}"
				[style.--carouselButtonBackgroundColorFirst]="config.carouselButtonBackgroundColorFirst | safe: 'style'"
				[style.--carouselButtonBackgroundColorSecond]="config.carouselButtonBackgroundColorSecond | safe: 'style'"
				[style.--carouselButtonBackgroundColorThird]="config.carouselButtonBackgroundColorThird | safe: 'style'"
				[style.--carouselButtonTextColor]="config.carouselButtonTextColor | safe: 'style'">
				{{button.title}}
			</button>
		</mat-card-actions>
	</mat-card>
</ng-template>