import { PayLoad } from "./IPayLoad";
import { MessageQR } from "./IQuickReply";

export interface Bubble {
	username: string;
	type_user: string;
	response: {
		date: string;
		type_user: string;
		text: any;
		type_message: string;
		quick_reply: MessageQR;
		payload: PayLoad;
		content: PayLoad;
		visible: boolean;
		isSound: boolean;
	}[]
}

export interface PsMessage {
	socialId: string;
	socialName: string;
	type: string;
	content: string;
	channelAccount: string;
	channelId: string;
	languageId?: string;
	projectId?: string;
	instanceId?: string;
	userId?: string;
	userName?: string;
	userEmail?: string;
	userTimezone?: string;
	media?: string;
}

export class PsMessage implements PsMessage {
	socialId: string;
	socialName: string;
	type: string;
	content: string;
	channelAccount: string;
	channelId: string;
	languageId?: string;
	projectId?: string;
	instanceId?: string;
	userId?: string;
	userName?: string;
	userEmail?: string;
	userTimezone?: string;
	media?: string;
	intentId?: string;
}
