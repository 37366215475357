<ng-container *ngIf="isList; else templateDetil">
	<div
		class="header-help"
		[style.--helpColorText]="config?.helpColorText"
		[style.--helpBackgroundDegradateFirst]="config?.helpBackgroundDegradateFirst | safe: 'style'"
		[style.--helpBackgroundDegradateSecond]="config?.helpBackgroundDegradateSecond | safe: 'style'"
		[style.--helpBackgroundDegradateThird]="config?.helpBackgroundDegradateThird | safe: 'style'">
		<div class="header-title">
			<div class="corner-help"></div>
			<div class="header-text">Help</div>
			<div class="corner-help"></div>
		</div>
		<div class="container-seach">
			<div class="container-seaching">
				<!-- <input type="text" class="input-search"  placeholder="prueba"/> -->
				<div class="search-text-icon">
					<mat-icon class="icon-search">search</mat-icon>
					<!-- <p>Search</p> -->
					<input type="text" class="input-search" placeholder="Search" />
				</div>
			</div>
		</div>
		<div></div>
	</div>
	<div class="body-help">
		<ng-container *ngIf="questions$ | async as questions">
			<div class="body-header-help">{{ questions.length }} colecciones</div>
			<div class="card-help" *ngFor="let item of questions">
				<div class="card-help-content" [style.--helpColorText]="config?.helpColorText" (click)="openDetail(item)">
					<div class="card-help-content-title">{{ item.title }}</div>
					<div class="card-help-content-text">{{ item.description }}</div>
					<div class="card-help-content-label">{{ item.category }}</div>
				</div>
				<mat-icon>navigate_next</mat-icon>
			</div>
		</ng-container>
	</div>
</ng-container>

<ng-template #templateDetil>
	<!-- <div>
		<div class="bbwidget-toolbar-back-button" (click)="goBack()">
			<mat-icon inline="true" class="toolbar-icon animated zoomIn shadow-custom"
				[ngStyle]="{'color': config?.userMessageTextColor}">chevron_left</mat-icon>
		</div>
	</div> -->
	<div
		class="header-help"
		[style.--helpBackgroundDegradateFirst]="config?.helpBackgroundDegradateFirst | safe: 'style'"
		[style.--helpBackgroundDegradateSecond]="config?.helpBackgroundDegradateSecond | safe: 'style'"
		[style.--helpBackgroundDegradateThird]="config?.helpBackgroundDegradateThird | safe: 'style'"
	>
		<div class="header-title">
			<div class="corner-help">
				<div class="bbwidget-toolbar-back-button" (click)="closeOpenDetail()">
					<mat-icon
						inline="true"
						class="toolbar-icon animated zoomIn shadow-custom"
						[ngStyle]="{ color: config?.userMessageTextColor }"
						>chevron_left</mat-icon
					>
				</div>
			</div>
			<div class="header-text">{{ title }}</div>
			<div class="corner-help"></div>
		</div>
	</div>
	<quill-view-html
		[content]="content"
		[ngStyle]="{ height: '300px', 'overflow-y': 'auto' }"
	></quill-view-html>
	<!-- <div class="detail-help" [innerHTML]="content"></div> -->
</ng-template>
