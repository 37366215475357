import {
	Component,
	EventEmitter,
	input,
	Input,
	OnInit,
	output,
	Output,
	ViewEncapsulation,
} from "@angular/core";
import { AppConfig } from "../../../../../app.config";
import { IButtonPL, PayLoad } from "../../../../core/interfaces/IPayLoad";
import { MessageQR } from "../../../../core/interfaces/IQuickReply";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../../../../shared/shared.module";
import { MatButton } from "@angular/material/button";

export interface Message {
	date: string;
	type_user: string;
	text: any;
	type_message: string;
	quick_reply: MessageQR;
	payload: PayLoad;
	content: PayLoad;
	visible: boolean;
	isSound: boolean;
}

@Component({
	selector: "chat-buttons-message",
	standalone: true,
	imports: [CommonModule, SharedModule, MatButton],
	templateUrl: "./buttons-message.component.html",
	styleUrl: "./buttons-message.component.css",
})
export class ButtonsMessageComponent {
	public helpResize = input<string>();
	public config = input<any>();
	public message = input<Message>();
	public buttonClicked = output<EventEmitter<IButtonPL>>();

	public onButtonClicked(button: IButtonPL) {
		// @ts-ignore
		this.buttonClicked.emit(button);
	}
}
