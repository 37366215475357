import { Pipe } from "@angular/core";

@Pipe({
	name: "describeLang",
})
export class DescribeLangPipe {
	constructor() {}

	public transform(value: string) {
		switch (value) {
			case "es":
				return "Español";
			case "en":
				return "Inglés";
			case "fr":
				return "Francés";
			case "de":
				return "Alemán";
			case "pt":
				return "Portugués";
			case "it":
				return "Italiano";
			default:
				return "No definido";
		}
	}
}
