import { NgModule, Optional, SkipSelf } from "@angular/core";
import { SplashScreenModule } from "./service/splash-screen/splash-screen.module";

@NgModule({
	imports: [SplashScreenModule],
})
export class ChatBotModule {
	/**
	 * Constructor
	 */
	constructor(@Optional() @SkipSelf() parentModule?: ChatBotModule) {
		if (parentModule) {
			throw new Error(
				"¡ChatBotModule ya ha sido cargado. Importar este módulo sólo en el AppModule!",
			);
		}
	}
}
