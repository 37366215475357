import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, switchMap, BehaviorSubject, tap, map } from "rxjs";
import { environment } from "../../../environments/environment";

const httpOptions = {
	headers: new HttpHeaders({
		"Content-Type": "application/json",
	}),
};
const endPointUrl1 = "questions";

@Injectable({
	providedIn: "root",
})
export class QuestionsService {
	private _apiUrl = environment.apiUrlPrefix;

	private _questions: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
	private _questionsDetail: BehaviorSubject<any | null> = new BehaviorSubject(
		null,
	);

	// selectedStep$ =  this._selectedStep.asObservable();

	constructor(private _httpClient: HttpClient) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for questions
	 */
	get questions$(): Observable<any> {
		return this._questions.asObservable();
	}

	// /**
	//  * Getter for questions
	//  */
	// get questionsDetail$(): Observable<any> {
	// 	return this._questionsDetail.asObservable();
	// }

	postQuestions(params: any): Observable<any> {
		return this._httpClient
			.post<any>(`${this._apiUrl}${endPointUrl1}`, params, httpOptions)
			.pipe(switchMap((response) => of(response)));
	}

	getQuestions(projectId: string): Observable<any> {
		return this._httpClient
			.get<any>(
				`${this._apiUrl}${endPointUrl1}?projectId=${projectId}`,
				httpOptions,
			)
			.pipe(
				tap((response) => {
					const { data } = response;
					this._questions.next(data);
				}),
			);
	}

	getQuestionsDetail(projectId: string, questionId: string): Observable<any> {
		return this._httpClient
			.get<any>(`${this._apiUrl}${endPointUrl1}/${questionId}`, httpOptions)
			.pipe(
				tap((response) => {
					const { data } = response;
					this._questionsDetail.next(data);
				}),
			);
	}

	putQuestions(params: any): Observable<any> {
		return this._httpClient
			.put<any>(`${this._apiUrl}${endPointUrl1}`, params, httpOptions)
			.pipe(switchMap((response) => of(response)));
	}

	deleteQuestions(projectId: string, questionId: string): Observable<any> {
		return this._httpClient
			.delete<any>(`${this._apiUrl}${endPointUrl1}/${questionId}`, httpOptions)
			.pipe(switchMap((response) => of(response)));
	}
}
